"use client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Dropdown } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addReport,
  addResort,
  deleteResort,
  deleteStudy,
  editResort,
  fetchStudy,
  updateStudyDescription,
} from "../../api/studyApi";
import AddItemModal from "../template/addItemModal";
import { Button } from "@material-tailwind/react";

import { useEffect, useMemo, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import {
  assignAccessToEmployee,
  fetchEmployeesWithAccess,
} from "../../api/userApi";
import { useUserContext } from "../user/authProvider";
import AddItemWithExistingPicker from "../template/AddItemWithExistingPicker";
import {
  addTemplate,
  createFromExistingTemplate,
  getAllAvailableTemplates,
  getTemplates,
} from "../../api/reportsApi";
import ItemsList from "../../components/lists/ItemsList";
import Tabs from "../../components/tabs";
import { FormTemplate, Report, ReportState } from "../../types/Rows";
import AddItemsModal from "../template/addItemsModal";
import { Input } from "@material-tailwind/react";
import MagnifyingGlass from "../../assets/icons/MagnifyingGlass";

const IncludedEmplyeesSection = ({ studyId }: { studyId: string }) => {
  const { userName } = useUserContext();
  const queryClient = useQueryClient();

  const { data, error, refetch } = useQuery(
    ["Employees", studyId!],
    async () => await fetchEmployeesWithAccess(studyId!),
    {
      initialData: [],
    }
  );
  const { mutate: addAccessToTheUser } = useMutation(
    ["Employees", studyId!],
    (name: string) => assignAccessToEmployee(name, studyId!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Employees", studyId!]);
      },
    }
  );

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <h1 className="text-3xl font-extrabold dark:text-white">
          <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
            Uwzględnieni CRO
          </small>
        </h1>{" "}
        <AddItemModal
          text="Wprowadź email"
          saveItem={(name) => {
            addAccessToTheUser(name);
          }}
        >
          <div className="p-3 ">Dodaj uzytkownika</div>
        </AddItemModal>
      </div>
      {/* <div className="p-3">
        {data?.result
          ?.filter((x: any) => x.id != currentUser.id)
          .map((x: any) => (
            <div key={currentUser.id} className="">
              <div className="ml-2">
                <div>- {x.userName}</div>
              </div>
            </div>
          ))}
      </div> */}
    </div>
  );
};

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

export const StudyPage = () => {
  let { studyId } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  const { mutate: deleteItem } = useMutation(
    async () => await deleteStudy(studyId!),
    {
      onSuccess: () => {
        navigate("/");
      },
    }
  );

  const { data, error, refetch } = useQuery(
    ["Studies", studyId!],
    () => fetchStudy(studyId!),
    {}
  );

  const { data: templates } = useQuery(
    ["Templates"],
    async () => getTemplates(),
    {
      enabled: studyId != null,
      initialData: [],
    }
  );

  useEffect(() => {
    if (data) {
      setItems([
        { text: "Badania", url: "/" } as NavigationBadge,
        { text: data.name, url: "" } as NavigationBadge,
      ]);
    }
  }, [data, setItems]);

  const { mutate: addResortMutation } = useMutation(
    async ({ name, address }: { name: string; address: string }) => {
      return addResort(studyId!, name, address);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: editResortMutation } = useMutation(
    async ({
      resortId,
      name,
      address,
    }: {
      resortId: string;
      name: string;
      address: string;
    }) => {
      return editResort(studyId!, resortId, name, address);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: updateDescription } = useMutation(
    async (description: string) => {
      return updateStudyDescription(studyId!, description);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: resortDelete } = useMutation(
    async (resortId: string) => {
      return deleteResort(studyId!, resortId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: addTemplateMutation } = useMutation(
    async (name: string) => {
      await addTemplate(name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Templates"]);
        queryClient.invalidateQueries(["Studies"]);
      },
    }
  );

  const { mutate: createFromExisting } = useMutation(
    async (id: string) => {
      await createFromExistingTemplate(studyId!, id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Templates"]);
        queryClient.invalidateQueries(["Studies"]);
      },
    }
  );

  const { mutate: addReportMutation } = useMutation(
    async ({
      resortId,
      templateId,
    }: {
      resortId: string;
      templateId: string;
    }) => {
      await addReport(studyId!, resortId, templateId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId!]);
      },
    }
  );

  const Templates = ({ templates }: { templates: FormTemplate[] }) => {
    const { data: allAvailableTemplates, refetch: fetchAvailableTemplates } =
      useQuery(
        ["AllTemplates"],
        async () => (await getAllAvailableTemplates()).data
      ); // TODO - change to have path Study-Template connection

    return (
      <div className="p-3">
        <ItemsList
          isLoading={false}
          data={templates}
          urlPrefix="/templates/"
          isSearchVisible
        >
          <div className="flex flex-1 items-end gap-3">
            <h1 className="text-5xl font-extrabold dark:text-white">
              <small className="font-semibold">Szablony </small>
            </h1>{" "}
            <AddItemWithExistingPicker
              text="Dodaj szablon"
              saveNew={(value) => {
                addTemplateMutation(value);
              }}
              saveExisting={(id: string) => createFromExisting(id)}
              items={async () =>
                (await allAvailableTemplates
                  .filter((x) => !templates.find((y) => y.id == x.id))
                  .map((x) => ({
                    id: x.id,
                    name: x.name,
                  }))) ?? []
              }
            >
              <AddIcon />
            </AddItemWithExistingPicker>
          </div>
        </ItemsList>
      </div>
    );
  };

  const Reports = () => {
    const [searchPhrase, setSearchPhrase] = useState<string>("");
    const onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
      setSearchPhrase(target.value.toLocaleLowerCase());

    return (
      <div>
        <div className="flex content-between justify-between">
          <div>
            <AddItemModal
              text="Edytuj opis"
              borderless={true}
              saveItem={(value) => updateDescription(value)}
              val={data.description}
            >
              <h1 className="text-5xl font-extrabold dark:text-white cursor-pointer hover:text-gray-700">
                {data.name}
              </h1>
            </AddItemModal>
          </div>
          <div className="flex gap-2">
            <Button variant="text">
              <AddItemsModal
                borderless
                text="Dodaj ośrodek:"
                headers={["Nazwa", "Adres"]}
                saveItems={(names: string[]) => {
                  addResortMutation({
                    name: names[0]?.toString() ?? "",
                    address: names[1]?.toString() ?? "",
                  });
                }}
              >
                Dodaj ośrodek
              </AddItemsModal>
            </Button>
          </div>
        </div>
        <div>
          <AddItemModal
            text="Edytuj opis"
            borderless={true}
            saveItem={(value) => updateDescription(value)}
            val={data.description}
          >
            <p className="p-3 cursor-pointer hover:text-gray-700">
              {data.description}{" "}
            </p>
          </AddItemModal>
        </div>
        <div className="flex flex-col gap-5 divide-y-2">
          {data.resorts
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((resort) => (
              <div className="ml-4">
                {/* <Disclosure defaultOpen>
              <Disclosure.Button className=""> */}
                <div className="flex flex-1 items-end justify-between">
                  <div className="flex flex-1 items-end gap-3 ">
                    <AddItemsModal
                      borderless
                      text="Edytuj ośrodek:"
                      vals={[resort.name, resort.address]}
                      headers={["Nazwa", "Adres"]}
                      saveItems={(names: string[]) => {
                        editResortMutation({
                          resortId: resort.id,
                          name: names[0]?.toString() ?? "",
                          address: names[1]?.toString() ?? "",
                        });
                      }}
                    >
                      <h1 className="text-5xl font-extrabold dark:text-white">
                        <small className="font-semibold text-gray-500 dark:text-gray-400 cursor-pointer hover:text-gray-700">
                          {resort.name}
                        </small>
                      </h1>{" "}
                    </AddItemsModal>
                    <Dropdown
                      inline
                      label={
                        <div
                          className=" self-center p-1
          rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
              flex
              "
                        >
                          <AddIcon />
                        </div>
                      }
                      arrowIcon={false}
                    >
                      {/* TODO: extract into a self contained component */}
                      {
                        <Dropdown.Header>
                          <Input
                            icon={<MagnifyingGlass />}
                            variant="outlined"
                            type="search"
                            label="Wyszukaj"
                            onKeyDown={(e) => e.stopPropagation()}
                            onChange={onSearchChange}
                          />
                        </Dropdown.Header>
                      }
                      {templates
                        .filter(({ name }) =>
                          name.toLocaleLowerCase().includes(searchPhrase)
                        )
                        .map((template) => (
                          <Dropdown.Item
                            onClick={() =>
                              addReportMutation({
                                resortId: resort.id,
                                templateId: template.id,
                              })
                            }
                          >
                            {template.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown>
                  </div>
                  <Button
                    variant="text"
                    onClick={async () => {
                      const res = window.confirm("Czy chcesz usunąć?");
                      if (res) await resortDelete(resort.id);
                    }}
                  >
                    Usuń ośrodek
                  </Button>{" "}
                </div>

                <h1 className="font-extrabold dark:text-white">
                  <small className="font-semibold italic text-gray-500 dark:text-gray-400">
                    {resort.address}
                  </small>
                </h1>
                {/* </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform  opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Disclosure.Panel className="text-gray-500"> */}
                <ItemsList
                  isLoading={false}
                  data={resort.reports}
                  urlPrefix="/reports/"
                  additionalInfoFactory={(data: Report) => {
                    let text = "";
                    let colour = "";
                    if (data.state == ReportState.INPROGRESS) {
                      text = "W trakcie";
                      colour = "gray-500";
                    }
                    if (data.state == ReportState.TO_VERIFY) {
                      text = "Do weryfikacji";
                      colour = "orange-500";
                    }
                    if (data.state == ReportState.TO_CORRECT) {
                      text = "Do poprawy";
                      colour = "orange-500";
                    }
                    if (data.state == ReportState.ACCEPTED) {
                      text = "Zaakceptowano";
                      colour = "green-600";
                    }

                    return <div className={`text-${colour} pr-5`}>{text}</div>;
                  }}
                >
                  {""}
                </ItemsList>
                {/* </Disclosure.Panel>
              </Transition>
            </Disclosure> */}

                {/* {resort.reports
                .sort((a, b) =>
                  new Date(a.created) < new Date(b.created) ? -1 : 1
                )
                .map((report) => (
                  <a
                    href={"/reports/" + report.id}
                    className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                  >
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {report.name}
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      {new Date(report.created)?.toLocaleString()}
                    </p>
                  </a>
                ))} */}
              </div>
            ))}
        </div>
      </div>
    );
  };

  if (data == null) {
    return <></>;
  }

  return (
    <div className="App">
      <Tabs
        categories={[
          { id: "1", component: <Reports />, title: "Raporty" },
          {
            id: "2",
            component: <Templates templates={templates} />,
            title: "Szablony",
          },
          {
            id: "3",
            component: <IncludedEmplyeesSection studyId={studyId!} />,
            title: "CRO",
          },
        ]}
      ></Tabs>
    </div>
  );
};
