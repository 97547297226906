export const RemoveItemComponent = ({action}: {action:  () => any }) =>{
return (  <div
    className="cursor-pointer pr-2"
    onClick={async() => {
      const res = window.confirm("Czy chcesz usunąć?");
      if (res)
        await action();
    }}
  >
    x
  </div>)
}