import React, { useState } from "react";
import { ProblemsDictionary } from "../../types/Metadata";
import { Button } from "@material-tailwind/react";

const Modal = ({
  headerTitle,
  setShowModal,
  saveItem,
  val = "",
}: {
  headerTitle: string;
  setShowModal: any;
  saveItem: (name: string) => void;
  val?: string;
}) => {
  const [value, setValue] = useState<string>(val);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
              <h3
                className="text-3xl font-semibold"
                style={{ textTransform: "none" }}
              >
                {headerTitle}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative flex-auto">
              <textarea
                style={{ height: "100%", width: "600px" }}
                className="border"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-solid border-slate-200 rounded-b">
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Zamknij
              </button>
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  saveItem(value);
                  setShowModal(false);
                }}
              >
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default function AddItemModal({
  saveItem,
  text,
  children,
  borderless = false,
  val = "",
}: {
  text: string;
  saveItem: (name: string) => void;
  children?: any;
  problemsDictionary?: ProblemsDictionary;
  borderless?: boolean;
  val?: string;
}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      {showModal && (
        <Modal
          val={val}
          headerTitle={text}
          saveItem={saveItem}
          setShowModal={setShowModal}
        />
      )}

      {borderless ? (
        <Button variant="text" size="sm" onClick={() => setShowModal(true)}>
          {children}
        </Button>
      ) : (
        <Button variant="outlined" size="sm" onClick={() => setShowModal(true)}>
          {children}
        </Button>
      )}
    </>
  );
}
