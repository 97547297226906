import { useEffect, useState } from "react";
import { Answer, RaportSection } from "../../../types/Rows";
import { Row } from "./Row";

export const ReportSection = ({
  index,
  section,
  items,
  reportId,
}: {
  index: number;
  section: RaportSection;
  items: Answer[];
  templateId: string;
  reportId: string;
}) => {
  const [itemsWithChildren, setItemsWithChildren] = useState<Answer[]>([]);
  const [isSettingChildren, setIsSettingChildren] = useState<boolean>(true);

  useEffect(() => {
    if (items) {
      let questionsCopy = new Map<string, Answer>();
      items.forEach((q) => questionsCopy.set(q.questionId, q));

      let res = items
        ?.map((element) => {
          if (element.parentId && questionsCopy.has(element.parentId)) {
            const val = questionsCopy.get(element.parentId);
            val.children = val.children
              ? [
                  ...val?.children?.filter(
                    (x) => x.answerId != element.answerId
                  ),
                  element,
                ]
              : [element];
          }
          return element;
        })
        .sort((x) => (x.parentId == null ? -1 : 1));

      questionsCopy = null;
      setItemsWithChildren(res);
      setIsSettingChildren(false);
    }
  }, [items]);

  return (
    <div className="sm:rounded-lg border border-neutral-900 m-3">
      <div
        className="grid grid-cols-11
      w-full
      sm:rounded-lg 
          font-extrabold
                 break-words
                text-gray-700 uppercase  bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <div className="col-span-7 flex p-1 pl-2 py-3 items-center justify-between">
          {index}. {section.name}
        </div>
        <div className="col-span-2 flex items-center justify-between">
          <div>Odpowiedź</div>
        </div>
        <div className="col-span-2 flex items-center justify-between">
          <div>Komentarz</div>
        </div>{" "}
      </div>
      {!isSettingChildren &&
        itemsWithChildren
          .filter((x) => !x.parentId)
          ?.sort((a, b) => (a.weight < b.weight ? -1 : 1))
          .map((item, i) => (
            <Row
              index={index + "." + (i + 1)}
              key={index + item.weight + item.questionId}
              input={item}
              reportId={reportId}
              depth={0}
            ></Row>
          ))}
    </div>
  );
};
