import { useMutation, useQueryClient } from "@tanstack/react-query";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { deleteProblem } from "../../api/reportsApi";
import { ProblemAnswer } from "../../types/Rows";

import { successToast } from "../../helpers/successToast";
import { TextAreaInput, TextAreaInputShort } from "../atomic/commentsInput";
import { DiscussionComponent } from "../atomic/discussionComponent";
import { formatToDateOnly } from "../lists/formatDate";
import { RemoveItemComponent } from "../atomic/removeItemComponent";

export const ProblemRowWithAnswer = ({
  index,
  input,
  setRadioButtonVal,
  description,
  setDescription,
  identificationDate,
  setIdentificationDate,
  resolvedDate,
  setResolvedDate,
  personIdentifying,
  setPersonIdentifying,
  reportId,
}: {
  index: number;
  input: ProblemAnswer;
  setRadioButtonVal: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  identificationDate: Date | null;
  setIdentificationDate: (value: Date) => void;
  resolvedDate: Date | null;
  setResolvedDate: (value: Date) => void;
  personIdentifying: string;
  setPersonIdentifying: (value: string) => void;
  reportId: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate: removeProblem } = useMutation(
    async ({ reportId, value }: { reportId: string; value: string }) => {
      return deleteProblem(reportId, value);
    },
    {
      onSuccess: (response) => {
        queryClient.resetQueries([reportId, "Problems"]);
        successToast();
      },
    }
  );

  return (
    <div
      className="grid grid-cols-11
      p-2
                  max-h-50"
    >
      <div className="col-span-2 self-center">
        <p>
          {index}. {input.category}
        </p>
      </div>
      <div className="col-span-2 flex items-center">
        <TextAreaInput
          maxLength={500}
          label="Opis"
          value={description}
          onChange={(e) => setDescription(e)}
        />
        {/* <p>{input.description}</p> */}
      </div>
      {/* <p className="col-span-2 overflow-y-auto max-h-50">{input.description}</p>
      <p className="col-span-2 overflow-y-auto max-h-50">{input.category}</p> */}

      <div className="col-span-1">
        <div
          className="p-1"
          onChange={(e: any) => {
            setRadioButtonVal(e.target.value);
          }}
        >
          <div>
            <label>
              <input
                defaultChecked={input.answer === "new"}
                type="radio"
                value="new"
                name={"radio-" + input.answerId}
              />{" "}
              Nowe
            </label>
          </div>
          <div>
            <label>
              <input
                defaultChecked={input.answer === "ongoing"}
                type="radio"
                value="ongoing"
                name={"radio-" + input.answerId}
              />{" "}
              Trwające
            </label>
          </div>
          <div>
            <label>
              <input
                defaultChecked={input.answer === "finished"}
                type="radio"
                value="finished"
                name={"radio-" + input.answerId}
              />{" "}
              Zakończone
            </label>
          </div>
        </div>
      </div>

      <div className="col-span-2 flex items-center">
        <TextAreaInputShort
          label="Osoba identyfikująca"
          value={personIdentifying}
          onChange={(e) => setPersonIdentifying(e)}
        />
      </div>
      <div className="col-span-2 self-center pl-1">
        <DatePicker
          format="dd/MM/y"
          value={identificationDate?.toString()}
          onChange={(date) => {
            let parsed = formatToDateOnly(date as Date);
            setIdentificationDate(parsed);
          }}
        />
      </div>

      <div className="col-span-2 self-center flex justify-between">
        <DatePicker
          format="dd/MM/y"
          value={resolvedDate?.toString()}
          onChange={(date) => {
            let parsed = formatToDateOnly(date as Date);
            setResolvedDate(parsed);
          }}
        />
        <div className="self-center cursor-pointer">
          <DiscussionComponent resourceId={input.answerId} />
        </div>
        <RemoveItemComponent
          action={() =>
            removeProblem({ reportId: reportId, value: input.answerId })
          }
        />
      </div>
    </div>
  );
};
