import axios from "axios";
import { ApiURI } from "../types/configs";
import { headers } from "./headers";

export const UsersInstance = axios.create({
  baseURL: ApiURI + "api/Users/",
});

export const loginUser = async (username: string, password: string) => {
  return await UsersInstance.post<{ token: string }>(
    "login",
    {
      username: username,
      password: password,
    },
    headers()
  );
};

export const registerUser = async (username: string, password: string) => {
  return await UsersInstance.post<{ token: string }>(
    "register",
    {
      username: username,
      password: password,
    },
    headers()
  );
};

export const fetchEmployeesWithAccess = async (studyId: string) => {
  return (await UsersInstance.get<any>("access/" + studyId, headers())).data;
};

export const assignAccessToEmployee = async (
  email: string,
  studyId: string
) => {
  return (await UsersInstance.post<any>(email + "/access/" + studyId, headers()))
    .data;
};
