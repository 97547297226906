import { Button } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { UploadFile } from "../../../api/reportsApi";
import {
  failurToast,
  successToast
} from "../../../helpers/successToast";

const FileUpload = ({ reportId }: { reportId: string }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // const [file, setFile] = useState(null);
  // const [fileName, setFileName] = useState("Choose File");
  const queryClient = useQueryClient();

  const onChange = (e) => {
    let file = e.target.files[0];
    let fileName = e.target.files[0].name;
    onSubmit(file, fileName);
  };

  const onSubmit = async (file, fileName) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await UploadFile(reportId, formData, fileName);
      successToast();
      queryClient.resetQueries([reportId, "Files"]);
    } catch (err) {
      console.error(err);
      failurToast("Nie udało się załączyć pliku");
    }
  };

  return (
    <div>
      <div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={onChange}
        />
        <Button onClick={handleClick} className="custom-button" variant="outlined">
          Dodaj plik
        </Button>
      </div>
    </div>
  );
};

export default FileUpload;
