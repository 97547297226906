import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  addSection,
  assignQuestionsFromTemplate,
  deleteTemplate,
  getTemplates,
  toggleTemplate,
  updateFootnote,
  updateFootnoteFul,
  updateQuestions,
  updateSections,
  updateTemplateName,
  updateTemplateProblemQuestions,
} from "../../api/reportsApi";
import { lockToast, successToast } from "../../helpers/successToast";
import {
  EditableReportQuestion,
  ProblemAnswer,
  TemplateEditableSection,
} from "../../types/Rows";
import { useTemplateContext } from "../../pages/template/templateContext";
import AddItemModal from "../../pages/template/addItemModal";
import { useCallback, useEffect, useState } from "react";
import { FaLockOpen } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import { useUserContext } from "../../pages/user/authProvider";
import AddItemWithExistingPicker, {
  InputType,
} from "../../pages/template/AddItemWithExistingPicker";
import { Button, Input } from "@material-tailwind/react";

const TemplatesToolbar = ({
  name,
  templateId,
  isLocked,
  author,
  footer,
  footerFul,
}: {
  name: string;
  templateId: string;
  isLocked: boolean;
  author: string;
  footer: string;
  footerFul: string;
}) => {
  const queryClient = useQueryClient();
  const { questions, sections } = useTemplateContext();
  const [templateName, setTemplateName] = useState<string>(name ?? "");
  const { userName } = useUserContext();
  const navigate = useNavigate();

  const { mutate: addSections } = useMutation(
    ({ id, section }: { id: string; section: string }) =>
      addSection(id, section),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Templates"]);
      },
    }
  );

  const { mutate: saveQuestions, isSuccess: isSaveQuestionsSuccess } =
    useMutation(
      async ({ questions }: { questions: EditableReportQuestion[] }) => {
        return updateQuestions(templateId!, questions);
      }
    );

  const { mutate: saveSections, isSuccess: isSaveSectionsSuccess } =
    useMutation(
      async ({ sections }: { sections: TemplateEditableSection[] }) => {
        return updateSections(templateId!, sections);
      }
    );
  const { mutate: toggleLock } = useMutation(() => toggleTemplate(templateId), {
    onSuccess: () => {
      successToast();
      queryClient.invalidateQueries(["Templates", templateId]);
    },
  });

  const { mutate: updateName } = useMutation(
    (name: string) => updateTemplateName(templateId, name),
    {}
  );

  const { mutate: copyFromExistingTemplate } = useMutation(
    ({ templateId, sourceId }: { templateId: string; sourceId: string }) =>
      assignQuestionsFromTemplate(templateId, sourceId),
    {
      onSuccess: () => {
        successToast();
        queryClient.invalidateQueries(["Templates", templateId]);
      },
    }
  );

  const { mutate: saveProblemQuestions } = useMutation(
    async ({
      templateId,
      problemAnswers,
    }: {
      templateId: string;
      problemAnswers: ProblemAnswer[];
    }) => {
      return await updateTemplateProblemQuestions(templateId, problemAnswers);
    },
    {
      // onSuccess: () => {
      //   queryClient.invalidateQueries(["Templates"]);
      // },
    }
  );

  const { data: studyTemplates } = useQuery(
    ["Templates"],
    async () => getTemplates(),
    {
      enabled: templateId != null,
    }
  );

  const { mutate: deleteTemplateAction } = useMutation(
    ({ templateId }: { templateId: string }) => deleteTemplate(templateId),
    {
      onSuccess: () => {
        navigate("", { replace: true });
      },
    }
  );

  const checkAuthor = useCallback(() => {
    if (author != userName) {
      toast.warning("Tylko autor moze zmienić status");
      return;
    }
    toggleLock();
  }, [author, userName, toggleLock]);

  const { mutate: editFootnote } = useMutation(
    ({ templateId, value }: { templateId: string; value: string }) =>
      updateFootnote(templateId, value)
  );

  const { mutate: editFootnoteFul } = useMutation(
    ({ templateId, value }: { templateId: string; value: string }) =>
      updateFootnoteFul(templateId, value)
  );

  const Locked = () => {
    if (isLocked) {
      return (
        <>
          {"Zablokowany"}{" "}
          <FaLock style={{ display: "inline" }} className="m-1" />
        </>
      );
    } else
      return (
        <>
          {"Odblokowany"}{" "}
          <FaLockOpen style={{ display: "inline" }} className="m-1" />
        </>
      );
  };

  return (
    <div className="flex justify-between items-center p-3 bg-gray-50 sticky top-0 border-b-black border-b-2 z-10">
      <h1 className="text-2xl">
        {" "}
        <Input
          title="Name"
          label="Nazwa szablonu"
          type="text"
          variant="static"
          style={{ width: "400px" }}
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </h1>
      <h3 className="text">Autor: {author}</h3>
      <div className="flex gap-2">
        <Button
          variant="outlined"
          onClick={async () => {
            if (isLocked) {
              lockToast();
              return;
            }
            let result = window.confirm(
              "Czy na pewno chcesz usunąć? Spowoduje to równiez usunięcie powiązanych raportów"
            );

            if (result) {
              // console.log("removing", templateId);
              // await deleteTemplate(templateId); // TODO - investigate why it deosn't work
              // navigate("../study/" + data?.studyId, { replace: true });

              await deleteTemplateAction({ templateId: templateId! });
            }
          }}
        >
          Usuń szablon
        </Button>

        <AddItemWithExistingPicker
          saveExisting={(sourceId) => {
            if (isLocked) {
              lockToast();
              return;
            }
            copyFromExistingTemplate({
              templateId: templateId!,
              sourceId: sourceId,
            });
          }}
          inputType={InputType.PICKFROMEXISTING}
          saveNew={() => {}}
          items={async () =>
            studyTemplates
              ?.map((x) => ({
                id: x.id,
                name: x.name,
              }))
              .filter((x) => x.id !== templateId)
          }
          text="Skopiuj z istniejącego"
        >
          Skopiuj z istniejącego
        </AddItemWithExistingPicker>

        <AddItemModal
          text="Dodaj sekcję"
          saveItem={(name) => {
            if (isLocked) {
              lockToast();
              return;
            }
            addSections({ id: templateId!, section: name });
          }}
        >
          Dodaj sekcję
        </AddItemModal>

        <Button
          variant="outlined"
          onClick={checkAuthor}
          className={`${isLocked ? "disabled" : ""}`}
        >
          {Locked()}
        </Button>
        <button
          onClick={() => {
            if (isLocked) {
              lockToast();
              return;
            }

            if (templateName != name) {
              updateName(templateName);
            }

            editFootnote({ templateId: templateId, value: footer });
            editFootnoteFul({ templateId: templateId, value: footerFul });

            Promise.all([
              saveQuestions({ questions: [...questions.values()] }),
              saveSections({ sections: [...sections.values()] }),
            ]).then(() => {
              successToast();
            });
          }}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold px-1 border border-gray-200 rounded ml-1"
        >
          <Button>Zapisz</Button>
        </button>
      </div>
    </div>
  );
};

export default TemplatesToolbar;
