import { useEffect, useState } from "react";
import { ProblemAnswer } from "../../types/Rows";
import { useProblemAnswers } from "../..";
import { ProblemRowWithAnswer } from "./problemsList";


export const ProblemRow = ({
  input, index, reportId,
}: {
  input: ProblemAnswer;
  index: number;
  reportId: string;
}) => {
  // load previous answers for a given row
  const [comment, setComment] = useState<string>(input.comment);
  const [identificationDate, setIdentificationDate] = useState<Date | null>(
    input?.identificationDate
  );

  const [description, setDescription] = useState(input.description);

  const [resolvedDate, setResolvedDate] = useState<Date | null>(
    input?.resolvedDate
  );
  const [personIdentifying, setPersonIdentifying] = useState<string>(
    input?.personIdentifying
  );

  const [radioButtonVal, setRadioButtonVal] = useState<string>(
    input.answer.toLocaleLowerCase()
  );
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const { updateProblemAnswer } = useProblemAnswers();

  useEffect(() => {
    if (!isInitialRender) {
      updateProblemAnswer({
        ...input,
        comment: comment,
        description: description,
        answer: radioButtonVal,
        identificationDate: identificationDate,
        resolvedDate: resolvedDate,
        personIdentifying: personIdentifying,
      });
    }
    setIsInitialRender(false);
  }, [
    comment,
    radioButtonVal,
    identificationDate,
    resolvedDate,
    description,
    personIdentifying,
  ]);

  return (
    <ProblemRowWithAnswer
      index={index + 1}
      input={input}
      description={description}
      setRadioButtonVal={setRadioButtonVal}
      identificationDate={identificationDate}
      setIdentificationDate={setIdentificationDate}
      resolvedDate={resolvedDate}
      setDescription={setDescription}
      setResolvedDate={setResolvedDate}
      personIdentifying={personIdentifying}
      setPersonIdentifying={setPersonIdentifying}
      reportId={reportId} />
  );
};
