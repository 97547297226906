import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { usePDF } from "react-to-pdf";
import { FaFileExport } from "react-icons/fa";
import {
  fetchMetadata,
  fetchProblems,
  fetchReport,
  fetchSections,
  getComments,
  getParticipants,
  GetReportFile,
  getReportFiles,
} from "../../api/reportsApi";
import { ProblemsSection } from "../../components/problemsSection/ProblemsSection";
import { ReviewComment, TemplateType } from "../../types/Rows";
import { ReportHeader } from "./ReportHeader";
import { SectionsList } from "./section/SectionsList";

import ReactPDF, { usePDF } from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";
import {
  NavigationBadge,
  useDiscussion,
  useNavigationBadgesStore,
  useReport,
} from "../..";
import ReportToolbar from "../../components/toolbar/ReportToolbar";
import { failureSaveToast } from "../../helpers/successToast";
import { useUserContext } from "../user/authProvider";
import { DocumentGenerator } from "./document/documentGenerator";
import { FinalComment } from "./metadata/finalComment";
import { ParticipantsSectionsList } from "./participantsSection";
import { ReportFiles } from "./section/ReportFiles";

function ReportPage() {
  let { reportId } = useParams();
  const { parsedName } = useUserContext();
  const { update: updateReport } = useReport();
  const { update: updateDiscussion } = useDiscussion();

  const { data, error } = useQuery(
    [reportId, "Report"],
    () => fetchReport(reportId!),
    {
      enabled: reportId != undefined,
    }
  );

  const { data: metadata } = useQuery(
    [reportId, "Metadata"],
    () => fetchMetadata(reportId!),
    {
      enabled: reportId != undefined,
    }
  );

  const { data: problems } = useQuery(
    [reportId, "Problems"],
    () => fetchProblems(reportId!),
    {
      enabled: reportId != undefined,
      initialData: [],
    }
  );

  const { data: sections } = useQuery(
    [reportId, "Sections"],
    () => fetchSections(reportId!),
    {
      enabled: reportId != undefined,
      initialData: [],
    }
  );
  const { data: participants } = useQuery(
    [reportId, "Participants"],
    async () => getParticipants(reportId),
    { enabled: reportId != null, initialData: [] }
  );

  const { data: reportFiles } = useQuery(
    [reportId, "Files"],
    async () => getReportFiles(reportId),
    { enabled: reportId != null, initialData: [] }
  );

  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  useEffect(() => {
    if (data) {
      updateReport(data);
      setItems([
        { text: "Badania", url: "/" } as NavigationBadge,
        {
          text: data.study,
          url: "/study/" + data.studyId,
          // links: allStudies?.map((link) => ({
          //   name: link.name,
          //   value: "/study/" + link.id,
          // })),
        } as NavigationBadge,
        {
          text: data.name,
          url: "/reports/" + data.id,
          // links: studies?.resorts?.flatMap((resort) =>
          //   resort?.reports?.flatMap((report) => ({
          //     name: resort.name + " " + report.name,
          //     value: "/reports/" + report.id,
          //   }))
          // ),
        } as NavigationBadge,
      ]);
    }
  }, [data, setItems]);

  useQuery(["Discussion"], async () => {
    var res = await getComments(reportId);
    var map = new Map<string, ReviewComment[]>();
    res.data.forEach((item) => {
      map.set(item.parentId, [...(map.get(item.parentId) ?? []), item]);
    });
    updateDiscussion(map);
    return map;
  });

  const MyDoc = ({
    types,
    isFul,
  }: {
    types: TemplateType[];
    isFul: boolean;
  }) => {
    const header = (isFul ? "Follow up letter" : "Raport") + " z " + data.name;
    const [isLoadingAttachements, setIsLoadingAttachements] =
      useState<boolean>(true);

    const [attachements, setAttachements] = useState<PDFDocument[]>([]);

    const Doc = () => (
      <DocumentGenerator
        reportName={header}
        resortName={data.resort}
        address={data.address}
        metadata={metadata}
        problemAnswers={problems}
        sections={sections}
        participants={participants}
        types={types}
        footer={isFul ? data.footerFul : data?.footer}
        author={parsedName}
        composedDate={data.toVerifyDate ?? new Date()}
      />
    );
    const [instance, updateInstance] = usePDF({
      document: <Doc />,
    });

    useEffect(() => {
      if (!reportFiles) {
        setIsLoadingAttachements(false);
      } else {
        const itemsCopy = [];
        reportFiles.forEach(async (item) => {
          console.log("getting file ", item);
          const f = await GetReportFile(item.id, item.id);
          const pdf = await PDFDocument.load(f);

          itemsCopy.push(pdf);
        });
        setAttachements(itemsCopy);
      }
    }, [reportFiles]);

    useEffect(() => {
      if (instance) updateInstance(<Doc />);
    }, [types, isFul]);

    if (instance.loading) return <div>Loading ...</div>;

    return (
      <div
        onClick={() => {
          let unansweredQuestions = sections
            .flatMap((x) => x.questionsWithAnswer)
            .filter(
              (x) =>
                (x.parentId == null || x.parentId === "") &&
                (x.answer == null || x.answer === "") &&
                !x.isBlank
            )
            .map((x) => x.description)
            .join(",\n- ");
          if (unansweredQuestions)
            alert("Pytania bez odpowiedzi\n- " + unansweredQuestions);
        }}
      >
        <div
          onClick={async () => {
            try {
              const mainDocBuffer = await (
                await ReactPDF.pdf(<Doc />).toBlob()
              ).arrayBuffer();
              const mainDocPdf = await PDFDocument.load(mainDocBuffer);
              // const url = URL.createObjectURL(blob);
              // window.open(url, "_blank"); // Opens the URL in a new window or tab
              const pdfFiles = [mainDocPdf, ...attachements];
              const mergedPdf = await PDFDocument.create();

              for (const pdf of pdfFiles) {
                const pages = await mergedPdf.copyPages(
                  pdf,
                  pdf.getPageIndices()
                );
                pages.forEach((page) => mergedPdf.addPage(page));
              }

              const pdfBytes = await mergedPdf.save();
              const blob = new Blob([pdfBytes], { type: "application/pdf" });
              const url = URL.createObjectURL(blob);

              window.open(url, "_blank"); // Open the merged PDF in a new window/tab
            } catch (error) {
              failureSaveToast("Błąd w generowaniu pliku PDF");
            }
            // mergePDFHandler([ ...attachements]);
          }}
          // href={instance.url} download={`${header + " - " + data.name}.pdf`}
        >
          {"Eksport"}{" "}
          <FaFileExport style={{ display: "inline" }} className="m-1" />
        </div>
      </div>
    );
  };

  return (
    <>
      {data && (
        <ReportToolbar
          resortName={data.resort}
          state={data.state}
          reportId={data.id}
          MyDoc={MyDoc}
          studyId={data.studyId}
        />
      )}
      <div>
        <div className="flex flex-row justify-between m-2">
          <div className="flex gap-2"></div>
        </div>
        <div>
          {metadata && (
            <ReportHeader
              name={data?.name ?? ""}
              address={data?.address ?? ""}
              templateId={data?.templateId ?? ""}
              resortName={data?.resort ?? ""}
              metadata={metadata}
            />
          )}

          {problems && (
            <ProblemsSection
              templateId={data?.templateId ?? ""}
              items={problems}
              reportId={reportId}
            />
          )}

          {participants && (
            <ParticipantsSectionsList
              items={participants}
              reportId={reportId}
            />
          )}

          {sections && (
            <SectionsList
              sections={sections}
              templateId={data?.templateId ?? ""}
              reportId={reportId}
            />
          )}
          {metadata && <FinalComment metadata={metadata} />}
          <ReportFiles files={reportFiles} reportId={reportId} />
        </div>
      </div>
    </>
  );
}

export const ReportPageWrapper = () => {
  return <ReportPage />;
};
