import { useState } from "react";
import { Metadata } from "../../../types/Metadata";
import { useMetadata } from "../../..";
import { TextAreaInput } from "../../../components/atomic/commentsInput";
import { ItemPopover } from "../../../components/atomic/itemPopover";

export const ParticipantsInput = ({ metadata }: { metadata: Metadata }) => {
  const [croParticipants, setCroParticipants] = useState<string>(
    metadata.croParticipants
  );
  const [resortParticipants, setReesortParticipants] = useState<string>(
    metadata.resortParticipants
  );
  const { updateMetadata } = useMetadata();

  return (
    <>
      <div className="text-xl text-center m-2">Uczestnicy <ItemPopover text="W miejscu CRO wpisz wszystkich uczestników wizyty z ramienia CRO w postaci: imię nazwisko (stanowisko), np. Jan Nowak (CRA). Listę uczestników CRO rozpocznij od imienia i nazwiska Monitora odpowiedzialnego za wizytę. 
      W miejscu Ośrodek wpisz wszystkich uczestników wizyty z ramienia Ośrodka w postaci: imię nazwisko (rola), np. Ewa Kowalska (PI). Listę uczestników Ośrodka rozpocznij od imienia i nazwiska Głównego Badacza."  /></div>
      <div className="sm:rounded-lg">

        <div
          className="grid grid-cols-2
              max-h-50
              divide-x gap-1"
        >
          <div className="col-span-1">
            <TextAreaInput       
            label="CRO"        
            maxWidth={false}      
            maxLength={500}
            value={croParticipants}
             onChange={(e) => {
                setCroParticipants(e);
                updateMetadata((metadata: Metadata) => {
                  metadata.croParticipants = e;
                  return metadata;
                })}} />
          </div>
          <div className="col-span-1">
            <TextAreaInput       
            label="Ośrodek"        
            maxWidth={false}      
            maxLength={500}
            value={resortParticipants}
            onChange={(e) => {
              setReesortParticipants(e);
              updateMetadata((metadata: Metadata) => {
                metadata.resortParticipants = e;
                return metadata;
              });
            }}

                />
          </div>
        </div>
      </div>
    </>
  );
};
