import { Metadata } from "./Metadata";

export type BaseDto = {
  id: string;
  name: string;
  created: Date;
  createdBy: string;
  edited: Date;
  EditedBy: string;
  description?: string;
};

export type FormTemplate = {
  name: string;
  id: string;
  created: Date;
  isLocked: boolean;
  problemQuestions: ProblemAnswer[];
  footer: string;
  footerFul: string;
} & BaseDto;

export type TemplateEditableSection = {
  id: string;
  name: string;
  questions: EditableReportQuestion[];
  weight: number;
};

export enum TemplateType {
  METADATA = 1,
  STATUS = 2,
  PARTICIPANTS = 4,
  ISSUES = 8,
  QUESTIONS = 16,
  FOOTER = 32,
  FINALCOMMENT = 64,
}

export type Report = {
  id: string;
  address: string;
  templateId: string;
  templateName?: string;
  toVerifyDate: Date;
  study: string;
  studyId: string;
  resort: string;
  resortId: string;
  name: string;
  created: Date;
  state: ReportState;
  footer: string;
  footerFul: string;
} & BaseDto;

export type EditableReportQuestion = {
  id: string;
  questionId: string;
  name: string;
  description: string;
  weight: number;
  isBlank: boolean;
  parentId: string;
  created: Date;
  requiredAnswerToBeVisible: string;
  children: EditableReportQuestion[];
} & BaseDto;

export type RaportSection = {
  id: string;
  name: string;
  questionsWithAnswer: Answer[];
  weight: number;
  questions: EditableReportQuestion;
};

export type ParticipantAnswer = {
  id: string;
  participantNumber: string;
  visitNumber: string;
  isFinshed: boolean;
  isVerified: boolean;
  comment: string;
} & BaseDto;

export type ReportFile = {
  id: string;
  name: string;
} & BaseDto;

export type Answer = {
  questionId: string;
  parentId?: string;
  answerId: string;
  description: string;
  answer: string;
  comment: string;
  weight: number;
  isBlank: boolean;
  created: Date;
  requiredAnswerToBeVisible: string;

  children: Answer[]; // not part of the response
};

export type ProblemAnswer = Answer & {
  descriptionId: number;
  categoryId: number;
  weight: number;

  category: string;
  personIdentifying: string;
  identificationDate: Date | null;
  resolvedDate: Date | null;
  author: string;
};

export type RowInput = {
  descriptionId: string;
};

export type Study = {
  id: string;
  name: string;
  description: string;
  resorts: Resort[];
  templates: FormTemplate[];
  created: Date;
} & BaseDto;

export type Resort = {
  id: string;
  name: string;
  address: string;
  reports: Report[];
} & BaseDto;

export type ReviewComment = {
  parentId: string;
  comment: string;
  state: number;
} & BaseDto;

export enum ReportState {
  INPROGRESS = 0,
  TO_VERIFY = 1,
  TO_CORRECT = 2,
  ACCEPTED = 3,
}

export type QuestionDTO = {
  id: string;
  name: string;
  description: string;
  parentId: string;
  answers: AnswerDto[];
} & BaseDto;

export type QuestionWithAnswers = {
  id: string;
  name: string;
  description: string;
};

export type AnswerDto = {
  id: string;
  answer: string;
  name: string;
  description: string;
  reportName: string;
  resortName: string;
  reportId: string;
  comment: string;
  parentId: string;
} & BaseDto;
