// src/Login.js

import React, { useState } from "react";
import "./Login.css"; // Custom CSS for styling

function LoginPage() {
  const [signInName, setSignInName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Normally you would handle authentication here
    // but Azure AD B2C will handle it after form submission
    (document.getElementById("loginForm") as any).submit();
  };

  return (
    <div className="login-container">
      <h1>Sign In</h1>
      <form id="loginForm" onSubmit={handleSubmit} method="post">
        <div>
          <label htmlFor="signInName">Email:</label>
          <input
            type="text"
            id="signInName"
            name="signInName"
            value={signInName}
            onChange={(e) => setSignInName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign In</button>
      </form>
    </div>
  );
}

export default LoginPage;
