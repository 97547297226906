import { useEffect, useState } from "react";
// import { ListBox, Pagination, StoryView, StorySkeleton, Ring } from ".";

import { Input } from "@material-tailwind/react";
import MagnifyingGlass from "../../assets/icons/MagnifyingGlass";
import { BaseDto, Study } from "../../types/Rows";
import {
  ALL_TIME,
  ASCENDING,
  CREATED_DATE,
  EDITED_DATE,
  NAME,
  ORDER_IN_OPTIONS,
  SORT_BY_OPTIONS
} from "../constants";
import ListBox from "./ListBox";
import StorySkeleton from "./StorySkeleton";
import StoryView from "./StoryView";

const PAGE_SIZE = 10;
const STORIES_OFFSET = 50;

export default function ItemsList({
  isLoading,
  data,
  children,
  urlPrefix,
  isSortVisible = false,
  additionalInfoFactory = (data: any) => null,
  isSearchVisible = false,
}: {
  isLoading: boolean;
  data: BaseDto[];
  children: any;
  urlPrefix: string;
  isSortVisible?: boolean;
  additionalInfoFactory?: (data: any) => any;
  isSearchVisible?: boolean,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentlySortBy, setCurrentlySortBy] = useState(NAME);
  const [currentlyOrderIn, setCurrentlyOrderIn] = useState(ASCENDING);
  const [currentFilter, setCurrentFilter] = useState(ALL_TIME);

  const [totalStories, setTotalStories] = useState(STORIES_OFFSET);
  const sortByDefault = (data: BaseDto[]) => {
    return Object.values(data).sort((a: BaseDto, b: BaseDto) =>
      currentlyOrderIn == ASCENDING
        ? a.name.toUpperCase().localeCompare(b.name.toUpperCase(), undefined, {
          numeric: true,
          sensitivity: "base",
        })
        : b.name.toUpperCase().localeCompare(a.name.toUpperCase(), undefined, {
          numeric: true,
          sensitivity: "base",
        })
    );
  };

  const [sortedData, setSortedData] = useState<BaseDto[]>(sortByDefault(data));

  useEffect(() => {
    let sorted;
    if (currentlySortBy === NAME) {
      sorted = sortByDefault(data);
    } else if (currentlySortBy === CREATED_DATE) {
      sorted = Object.values(data).sort((a: BaseDto, b: BaseDto) =>
        currentlyOrderIn == ASCENDING
          ? new Date(a.created) > new Date(b.created)
            ? -1
            : 1
          : new Date(a.created) > new Date(b.created)
            ? 1
            : -1
      );
    } else if (currentlySortBy === EDITED_DATE) {
      sorted = Object.values(data).sort((a: BaseDto, b: BaseDto) =>
        currentlyOrderIn == ASCENDING
          ? new Date(a.edited) > new Date(b.edited)
            ? -1
            : 1
          : new Date(a.edited) > new Date(b.edited)
            ? 1
            : -1
      );
    }
    setSortedData(sorted);
  }, [data, currentlySortBy, currentlyOrderIn]);

  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => setSearchPhrase(target.value.toLocaleLowerCase());

  return (
    <>
      <div className="grid lg:grid-cols-1 gap-7 lg:gap-x-5">
        {isLoading ? (
          <>
            <StorySkeleton />
            <StorySkeleton />
            <StorySkeleton />
            <StorySkeleton />
          </>
        ) : (
          <>
            {/* <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pagesCount={Math.ceil(storyIds.length / PAGE_SIZE)}
            /> */}
            <div className="flex justify-between">
              <>{children}</>
              <div className="flex grow justify-end gap-4">
                {isSortVisible && (
                  <div className="flex flex-wrap space-x-4">
                    <div className="hidden w-40 lg:block">
                      <ListBox
                        label="Sortuj"
                        selectedOption={currentlyOrderIn}
                        setSelectedOption={setCurrentlyOrderIn}
                        options={ORDER_IN_OPTIONS}
                      />
                    </div>
                    <div className="w-40">
                      <ListBox
                        label="Według"
                        selectedOption={currentlySortBy}
                        setSelectedOption={setCurrentlySortBy}
                        options={SORT_BY_OPTIONS}
                      />
                    </div>
                    {/* <div className="w-40 mr-auto:important sm:mr-0:important">
                  <ListBox
                    label="Show Only"
                    selectedOption={currentFilter}
                    setSelectedOption={setCurrentFilter}
                    options={FILTER_OPTIONS}
                  />
                </div> */}
                  </div>
                )}
                {isSearchVisible && (
                  <Input containerProps={{ className: "w-auto max-w-[18rem] self-end" }} icon={<MagnifyingGlass />} variant="outlined" type="search" label="Wyszukaj" onChange={onSearchChange} />
                )}
              </div>
            </div>
            {sortedData
              .filter(({ name }: BaseDto) => name.toLocaleLowerCase().includes(searchPhrase))
              .map((item: Study) => (
                <StoryView
                  currentFilter={currentFilter}
                  additionalInfoFactory={additionalInfoFactory}
                  key={item.id}
                  data={item}
                  urlPrefix={urlPrefix}
                />
              ))}
            {/* <span className="text-center rounded-md mb-28 sm:mb-20">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-orange-600 border border-transparent rounded-md hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700"
                onClick={() => setTotalStories(totalStories + STORIES_OFFSET)}
              >
                Load More
              </button>
            </span> */}
          </>
        )}
      </div>
    </>
  );
}
