import React, { useState } from "react";
import { ProblemsDictionary } from "../../types/Metadata";

const Modal = ({
  headerTitle,
  setShowModal,
  saveItems,
  headers,
  vals,
}: {
  headerTitle: string;
  setShowModal: any;
  saveItems: (name: string[]) => void;
  headers: string[];
  vals: string[];
}) => {
  const [values, setValues] = useState<string[]>(
    vals ? [...vals] : Array.apply(null, Array(headers.length)).map(() => "")
  );
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
              <h3
                className="text-3xl font-semibold"
                style={{ textTransform: "none" }}
              >
                {headerTitle}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative flex-auto">
              {headers.map((header, index) => (
                <div className="px-10">
                  <div>{header}</div>
                  <textarea
                    style={{ height: "100%", width: "600px" }}
                    className="border"
                    value={values[index]}
                    onChange={(e) => {
                      let copy = [...values];
                      copy[index] = e.target.value;
                      setValues(copy);
                    }}
                  />
                </div>
              ))}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-solid border-slate-200 rounded-b">
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Zamknij
              </button>
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  saveItems(values);
                  setShowModal(false);
                }}
              >
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default function AddItemsModal({
  saveItems,
  text,
  children,
  borderless = false,
  headers,
  vals = [],
}: {
  text: string;
  saveItems: (name: string[]) => void;
  headers: string[];
  children?: any;
  problemsDictionary?: ProblemsDictionary;
  borderless?: boolean;
  vals?: string[];
}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      {borderless ? (
        <div onClick={() => setShowModal(true)}>{children}</div>
      ) : (
        <button
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold px-1 border border-gray-200 rounded ml-2"
          type="button"
          onClick={() => setShowModal(true)}
        >
          {children}
        </button>
      )}

      {showModal && (
        <Modal
          vals={vals}
          headerTitle={text}
          saveItems={saveItems}
          headers={headers}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}
