export const formatDateTime = (date?: Date) => {
  if (!date) return "";
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  } as Intl.DateTimeFormatOptions;
  return new Date(date).toLocaleDateString("pl-PL", options);
};

export const formatDate = (date?: Date) => {
  if (!date) return "";
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  } as Intl.DateTimeFormatOptions;
  return new Date(date).toLocaleDateString("pl-PL", options);
};

export const formatDateNumbers = (date?: Date) => {
  if (!date) return "";
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  } as Intl.DateTimeFormatOptions;
  return new Date(date).toLocaleDateString("pl-PL", options);
};

export const formatToDateOnly = (date: Date) => {
  const parsed = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  );
  return parsed;
};
