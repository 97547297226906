import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  EditableReportQuestion,
  TemplateEditableSection,
} from "../../types/Rows";

export interface TemplateContextProps {
  sections: Map<string, TemplateEditableSection>;
  updateSection: (section: TemplateEditableSection) => void;
  questions: Map<string, EditableReportQuestion>;
  updateQuestion: (question: EditableReportQuestion) => void;
  updateQuestionWeight: (
    question: EditableReportQuestion,
    weigh: number
  ) => void;
}

export const TemplateContextDefaults = {
  sections: new Map<string, TemplateEditableSection>(),
  updateSection: (section: TemplateEditableSection) => {},
  questions: new Map<string, EditableReportQuestion>(),
  updateQuestion: (question: EditableReportQuestion) => {},
  updateQuestionWeight: (
    question: EditableReportQuestion,
    weight: number
  ) => {},
};

export const TemplateContext = createContext<TemplateContextProps>(
  TemplateContextDefaults
);

export const TemplateContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [sections, setSections] = useState<
    Map<string, TemplateEditableSection>
  >(new Map<string, TemplateEditableSection>());

  const [questions, setQuestions] = useState<
    Map<string, EditableReportQuestion>
  >(new Map<string, EditableReportQuestion>());

  const updateQuestion = useCallback(
    (item: EditableReportQuestion) => {
      let copy = new Map<string, EditableReportQuestion>(questions);
      copy.set(item.id, item);
      setQuestions(copy);
    },
    [questions, setQuestions]
  );

  const updateQuestionWeight = useCallback(
    (question: EditableReportQuestion, weight: number) => {
      let copy = new Map<string, EditableReportQuestion>(questions);
      let item = copy.get(question.id);
      if (item == null) {
        item = question;
        copy.set(item.id, item);
      }
      item.weight = weight;
      
      setQuestions(copy);
    },
    [questions, setQuestions]
  );

  const updateSection = useCallback(
    (section: TemplateEditableSection) => {
      let answersCopy = new Map<string, TemplateEditableSection>(sections);

      answersCopy.set(section.id, section);
      setSections(answersCopy);
    },
    [sections, setSections]
  );

  const state = useMemo(
    () => ({
      sections,
      updateSection,
      questions,
      updateQuestion,
      updateQuestionWeight,
    }),
    [sections, updateSection, questions, updateQuestion, updateQuestionWeight]
  );

  return (
    <TemplateContext.Provider value={state}>
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => useContext(TemplateContext);
