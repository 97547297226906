import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewProblem,
  addProblemFromExisting,
  updateTemplateProblemAnswers,
} from "../../api/reportsApi";
import { ProblemsDictionary } from "../../types/Metadata";
import { ProblemAnswer } from "../../types/Rows";
import { useProblemAnswers } from "../..";
import { getTemplateProblems } from "../../api/questionsApi";
import { successToast } from "../../helpers/successToast";
import AddItemWithExistingPicker, {
  InputType,
} from "../../pages/template/AddItemWithExistingPicker";
import { PlusIcon } from "../../types/PlusIcon";
import { ProblemRow } from "./ProblemRow";
import { sortIssues } from "../helpers/helpers";

export const ProblemsSection = ({
  items,
  reportId,
}: {
  items: ProblemAnswer[];
  templateId: string;
  reportId: string;
}) => {
  const { problemAnswers } = useProblemAnswers();

  const { mutateAsync: saveProblemsAnswers, isSuccess: isSaveProblemSuccess } =
    useMutation(
      async (problemAnswers: ProblemAnswer[]) => {
        if (problemAnswers.length > 0)
          return updateTemplateProblemAnswers(reportId!, problemAnswers);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([reportId, "Problems"]);
        },
      }
    );

  // TODO is it needed
  const { data } = useQuery(
    ["ReportProblemsDictionary"],
    async () => getTemplateProblems(),
    { initialData: { categories: [], descriptions: [] } as ProblemsDictionary }
  );
  const queryClient = useQueryClient();

  const { mutate: addProblemTypeToDictionary } = useMutation(
    async ({ reportId, value }: { reportId: string; value: string }) =>
      addNewProblem(reportId, value),
    {
      onSuccess: (response) => {
        queryClient.resetQueries([reportId, "Problems"]);
        successToast();
      },
    }
  );

  const { mutate: addProblemQuestions } = useMutation(
    async ({
      reportId,
      categoryId,
    }: {
      reportId: string;
      categoryId: string;
    }) => addProblemFromExisting(reportId, categoryId),
    {
      onSuccess: (response) => {
        queryClient.resetQueries([reportId, "Problems"]);
        successToast();
      },
    }
  );

  return (
    <div>
      <div className="text-xl text-center mt-1">Lista znalezisk</div>

      <div className="sm:rounded-lg border border-neutral-900 m-3 ">
        <div
          className="grid grid-cols-11
        w-full
        font-extrabold
        sm:rounded-lg
                   break-words 
         text-gray-700 uppercase  bg-gray-50 dark:bg-gray-700 dark:text-gray-400 p-2"
        >
          <div className="col-span-2 ">Kategoria</div>
          <div className="col-span-2 ">Opis</div>
          <div className="col-span-1 ">Status</div>
          <div className="col-span-2 ">Osoba identyfikująca</div>
          <div className="col-span-2 ">Data identyfikacji</div>
          <div className="col-span-2 flex justify-between">
            Data rozwiązania
            <AddItemWithExistingPicker
              saveExisting={(value) => {
                saveProblemsAnswers([...problemAnswers.values()]).then(() =>
                  addProblemQuestions({
                    reportId: reportId,
                    categoryId: value,
                  })
                );
              }}
              inputType={InputType.ALLOWALL}
              saveNew={(value) => {
                saveProblemsAnswers([...problemAnswers.values()]).then(() =>
                  addProblemTypeToDictionary({
                    reportId: reportId,
                    value: value,
                  })
                );
              }}
              items={async () => {
                let items = await getTemplateProblems();
                return items.categories.map((x) => ({
                  id: x.id.toString(),
                  name: x.value,
                }));
              }}
              text="Wybierz kategorię"
            >
              <PlusIcon />
            </AddItemWithExistingPicker>
          </div>
        </div>
        {sortIssues(items).map((item, index) => (
          <div key={"problemRow" + item.answerId}>
            <ProblemRow
              index={index}
              key={index + item.answerId}
              input={item}
              reportId={reportId}
            ></ProblemRow>
            <div style={{ height: "1px" }} className="w-full bg-gray-200"></div>
          </div>
        ))}
      </div>
    </div>
  );
};
