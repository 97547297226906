import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { FaRegComment } from "react-icons/fa6";
import { updateCommentState } from "../../api/reportsApi";
import { ReviewComment } from "../../types/Rows";

export default function CommentsModal({
  comments,
  addItem,
  reportId,
}: {
  comments: ReviewComment[];
  addItem: (val: string) => void;
  reportId: string;
}) {
  let [isOpen, setIsOpen] = useState(false);
  let [value, setValue] = useState<string>("");
  const queryClient = useQueryClient();

  function closeModal() {
    setIsOpen(false);
  }

  const { mutate: updateState } = useMutation(
    async (val: number) =>
      await updateCommentState(reportId, comments[0].id, val),
    {
      onSuccess: () => {
        queryClient.resetQueries(["Discussion"]);
      },
    }
  );

  function openModal() {
    setIsOpen(true);
  }

  const sortedComments = comments.sort((a, b) =>
    new Date(a.created) < new Date(b.created) ? -1 : 1
  );

  return (
    <>
      <div className="inset-0 flex items-center justify-center">
        <button type="button" onClick={openModal} className=" ">
          <FaRegComment
            color={sortedComments[0]?.state === 0 ? "orange" : "black"}
          />
          {comments.length > 0 && comments?.length?.toString()}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {sortedComments.map((comment) => (
                    <div className="mt-2 ">
                      <p className="text-sm text-gray-700 font-bold">
                        {comment.createdBy}{" "}
                        {new Date(comment.created).toLocaleDateString()}{" "}
                        {new Date(comment.created).toLocaleTimeString()}{" "}
                      </p>
                      <p className="text-sm text-gray-500">{comment.comment}</p>
                    </div>
                  ))}

                  <div>
                    <textarea
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      style={{ width: "100%" }}
                      className="border-solid border-gray-300 border mt-5 rounded"
                    />
                  </div>
                  <div className="flex flex-row mt-1">
                    <button
                      type="button"
                      className="inline-flex justify-end rounded-md text-sm mx-2 font-medium"
                      onClick={async () => {
                        if (sortedComments) {
                          await updateState(0); // TOOD -add type for state
                        }

                        await addItem(value);
                        setValue("");
                      }}
                    >
                      Dodaj komentarz
                    </button>
                    {sortedComments && (
                      <button
                        type="button"
                        className="inline-flex justify-end rounded-md text-sm mx-2 font-medium"
                        onClick={async () => {
                          if (sortedComments) {
                            await updateState(1); // TOOD -add type for state
                          }
                          closeModal();
                          setValue("");
                        }}
                      >
                        Oznacz jako rozwiązane
                      </button>
                    )}
                    <button
                      type="button"
                      className="inline-flex justify-end rounded-md mx-2 text-sm font-medium"
                      onClick={closeModal}
                    >
                      Zamknij
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
