import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReportState, TemplateType } from "../../types/Rows";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  copyAnswersFromPreviousReport,
  deleteReport,
  updateReportState,
} from "../../api/reportsApi";
import AddItemWithExistingPicker, {
  InputType,
} from "../../pages/template/AddItemWithExistingPicker";
import { fetchStudy } from "../../api/studyApi";
import { successToast } from "../../helpers/successToast";
import { SaveItems } from "../../pages/report/saveItems";
import { FaRegCopy } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  useMetadata,
  useParticipantAnswers,
  useProblemAnswers,
  useSectionQuestions,
} from "../..";
import { useOutsideAlerter } from "../../hooks/useOutsideAlert";
import { Button } from "@material-tailwind/react";

const IconDown = () => (
  <svg
    className="-mr-1 h-5 w-5 text-gray-400"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
      clipRule="evenodd"
    />
  </svg>
);
const ReportToolbar = ({
  state,
  resortName,
  reportId,
  MyDoc,
  studyId,
}: {
  reportId: string;
  resortName: string;
  state: ReportState;
  MyDoc: any;
  studyId: string;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const [isExportLocked, setIsExportLocked] = useState<boolean>(false);
  const { problemAnswers } = useProblemAnswers();
  const { questionAnswers } = useSectionQuestions();
  const { participantAnswers } = useParticipantAnswers();

  useEffect(() => {
    if (
      problemAnswers.size > 0 ||
      questionAnswers.size > 0 ||
      participantAnswers.size > 0
    ) {
      setIsExportLocked(true);
    }
  }, [
    problemAnswers.entries(),
    questionAnswers.entries(),
    participantAnswers.entries(),
  ]);

  const { metadata } = useMetadata();

  const { mutate: deleteTemplateAction } = useMutation(
    (reportId: string) => deleteReport(reportId),
    {
      onSuccess: () => {
        navigate("../study/" + studyId, { replace: true });
      },
    }
  );

  const invalidateReport = useCallback(
    () => queryClient.resetQueries([reportId, "Report"]),
    [reportId, queryClient]
  );

  const { mutateAsync: copyFromExisting } = useMutation(
    async ({ reportId, sourceId }: { reportId: string; sourceId: string }) =>
      await copyAnswersFromPreviousReport(reportId, sourceId),
    {
      onSuccess: () => {
        successToast();
        invalidateReport();
        window.location.reload();
      },
    }
  );

  const Types = ({ types }: { types: Map<string, TemplateType> }) => {
    const [selectedValues, setSelectedValues] = useState<TemplateType[]>([
      TemplateType.ISSUES,
      TemplateType.STATUS,
      TemplateType.METADATA,
      TemplateType.PARTICIPANTS,
      TemplateType.QUESTIONS,
      TemplateType.FOOTER,
      TemplateType.FINALCOMMENT,
    ]);

    useOutsideAlerter(wrapperRef, () => setIsOpen(false));

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [headerName, setHeaderName] = useState("Report");
    const [isFul, setIsFul] = useState<boolean>(false);

    const handleCheckboxChange = (value) => {
      if (selectedValues.includes(value)) {
        setSelectedValues(selectedValues.filter((v) => v !== value));
      } else {
        setSelectedValues([...selectedValues, value]);
      }
    };

    return (
      <div className="relative inline-block text-left" style={{ zIndex: 100 }}>
        <div>
          <button
            type="button"
            className="inline-flex w-full justify-center gap-x-1.5 rounded-xl "
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={() => {
              if (isExportLocked) {
                alert("Zapisz zmiany zanim zrobisz eksport");
                return;
              }
              setIsOpen(!isOpen);
            }}
          >
            Eksportuj do PDF
            <IconDown />
          </button>
        </div>
        {isOpen && !isExportLocked && (
          <div
            style={{ zIndex: 100 }}
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <div role="none" className="px-4 py-2 z-10">
              <div>
                <label>
                  <input
                    defaultChecked={true}
                    type="radio"
                    value={"Raport"}
                    name={"radio-" + "report"}
                    onChange={(e: any) => {
                      setIsFul(false);
                      //setHeaderName("Raport");
                    }}
                  />{" "}
                  Raport
                </label>
              </div>
              <div>
                <label>
                  <input
                    defaultChecked={false}
                    type="radio"
                    value="Follow up letter"
                    name={"radio-" + "report"}
                    onChange={(e: any) => {
                      setIsFul(true);

                      //setHeaderName("Follow up letter");
                    }}
                  />{" "}
                  Follow up letter
                </label>
              </div>
            </div>

            <div className="py-1 z-10" role="none">
              {[...types.keys()].map((key) => (
                <label
                  title={key}
                  key={key}
                  className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:text-gray-600"
                >
                  <input
                    type="checkbox"
                    id={"checkbox" + key}
                    value={types.get(key)}
                    checked={selectedValues.includes(types.get(key))}
                    onChange={() => handleCheckboxChange(types.get(key))}
                  />
                  <span className="pl-2">{key}</span>
                </label>
              ))}
            </div>
            <div className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 z-10">
              <MyDoc isFul={isFul} types={selectedValues} />

              {/* {metadata && MyDoc != null && isOpen && (
                <PDFDownloadLink
                  fileName={`${headerName + " - " + resortName}.pdf`}
                  document={
                    <MyDoc header={headerName} types={selectedValues} />
                  }
                >
                  {({ blob, url, loading, error }) => (
                    <>
                      {"Eksport"}{" "}
                      <FaFileExport
                        style={{ display: "inline" }}
                        className="m-1"
                      />
                    </>
                  )}
                </PDFDownloadLink>
              )} */}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="sticky top-0 flex justify-between items-center p-3 bg-gray-50   border-b-black border-b-2"
      style={{ zIndex: 1000, width: "100%" }}
    >
      <ReportStateItems state={state} reportId={reportId} />
      <div className="flex gap-2">
        <AddItemWithExistingPicker
          saveExisting={(sourceId) => {
            copyFromExisting({ reportId: reportId!, sourceId: sourceId });
          }}
          inputType={InputType.PICKFROMEXISTING}
          saveNew={() => {}}
          items={async () => {
            let studies = await fetchStudy(studyId!);
            return (
              studies?.resorts
                .flatMap((x) => ({ reports: x.reports, resortName: x.name }))
                ?.flatMap((x) =>
                  x.reports
                    .map((report) => ({
                      id: report.id,
                      name: x.resortName + " - " + report.name,
                    }))
                    .filter((x) => x.id != reportId)
                ) ?? []
            );
          }}
          text="Skopiuj z istniejącego"
        >
          {"Skopiuj z istniejącego"}{" "}
          <FaRegCopy style={{ display: "inline" }} className="m-1" />
        </AddItemWithExistingPicker>

        <Button
          variant="outlined"
          onClick={() => {
            let result = window.confirm("Czy na pewno chcesz usunąć?");

            if (result) {
              deleteTemplateAction(reportId!);
            }
          }}
        >
          Usuń raport
          <FaDeleteLeft style={{ display: "inline" }} className="m-1" />
        </Button>
        <button
          className="border border-gray-800 border-solid py-2 px-4 rounded-lg shadow-m focus:ring-2 focus:ring-opacity-75"
          ref={wrapperRef}
        >
          <Types
            types={
              new Map<string, TemplateType>([
                ["Informacje ogólne", TemplateType.METADATA],
                ["Status", TemplateType.STATUS],
                ["Lista znalezisk", TemplateType.ISSUES],
                ["Pacjenci", TemplateType.PARTICIPANTS],
                ["Pytania", TemplateType.QUESTIONS],
                ["Komentarz końcowy", TemplateType.FINALCOMMENT],
                ["Stopka", TemplateType.FOOTER],
              ])
            }
          />
        </button>
      </div>
      <SaveItems
        problemAnswers={problemAnswers}
        questionAnswers={questionAnswers}
        participantAnswers={participantAnswers}
        metadata={metadata}
        setIsExportLocked={setIsExportLocked}
        reportId={reportId!}
      />
    </div>
  );
};

function ReportStateItems({
  state,
  reportId,
}: {
  reportId: string;
  state: ReportState;
}) {
  const [reportState, setReportState] = useState<ReportState>(state);

  const changeState = async (state: ReportState) => {
    setReportState(state);
    await mutate(state);
  };

  const { mutate } = useMutation(
    async (val: ReportState) => await updateReportState(reportId, val)
  );

  const items: { state: ReportState; colour: string; name: string }[] = [
    {
      state: ReportState.INPROGRESS,
      colour: "border-gray-500",
      name: "W trakcie",
    },
    {
      state: ReportState.TO_VERIFY,
      colour: "border-orange-500",
      name: "Do weryfikacji",
    },
    {
      state: ReportState.TO_CORRECT,
      colour: "border-orange-500",
      name: "Do poprawy",
    },
    {
      state: ReportState.ACCEPTED,
      colour: "border-green-600",
      name: "Zaakceptowany",
    },
  ];

  return (
    <div className="grid grid-cols-4 gap-1 ">
      {items.map((item) => (
        <div
          className={`flex border-2 border-gray-200 rounded dark:border-gray-700 ${
            reportState === item.state ? item.colour : ""
          }`}
        >
          <input
            defaultChecked={reportState == item.state}
            id={`bordered-radio-${item.name}}`}
            type="radio"
            value=""
            name="bordered-radio"
            className="hidden peer cursor-pointer"
          />
          <label
            htmlFor="bordered-radio-1"
            className="w-full py-4 text-center text-sm px-2 font-medium text-gray-900 dark:text-gray-300"
            onClick={() => changeState(item.state)}
          >
            {item.name}
          </label>
        </div>
      ))}
    </div>
  );
}

export default ReportToolbar;
