const tenant = "ScientiaTut.onmicrosoft.com";
const signInPolicy = "B2C_1_Scientia";
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
// Msal Configurations
// export const signInConfig = {
//   auth: {
//     authority: signInAuthority,
//     clientId: applicationID,
//     redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
//     validateAuthority: false,
//   },
//   cache: {
//     cacheLocation: "sessionStorage",
//     storeAuthStateInCookie: true,
//   },
// };
// // Authentication Parameters
export const authenticationParameters = {
  scopes: [
    "https://ScientiaTut.onmicrosoft.com/a8cefc98-9a74-495a-990e-8ae73422c0e4/reports-api",
  ],
};

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_Scientia",
  },
  authorities: {
    signUpSignIn: {
      authority:
        // "https://ScientiaTut.b2clogin.com/ScientiaTut.onmicrosoft.com/oauth2/v2.0/",
        //  "https://ScientiaTut.b2clogin.com/ScientiaTut.onmicrosoft.com/B2C_1_Scientia",
         signInAuthority
        //  "https://scientiatut.b2clogin.com/ScientiaTut.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_Scientia"
    },
    // forgotPassword: {
    //     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
    // },
    // editProfile: {
    //     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
    // },
  },
  authorityDomain: "ScientiaTut.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: "2bdd5355-aa6b-45d8-80a5-f4d11bc15d48", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [
    "https://ScientiaTut.onmicrosoft.com/a8cefc98-9a74-495a-990e-8ae73422c0e4/reports-api",
  ],
};
