import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { getAllQuestions } from "../../api/questionsApi";
import { GenericItemsListPage } from "./genericItemsListPage";

export const QuestionsPage = () =>{ 
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["Questions"],
    getAllQuestions,
    {
      initialData: [],
    }
  );

  useEffect(() => {
    if (data) {
      setItems([{ text: "Pytania", url: "/Questions" } as NavigationBadge]);
    }
  }, [data, setItems]);
 
  return (<GenericItemsListPage items={data} isFetching={isFetching} urlPrefix="questions/" /> )
};
