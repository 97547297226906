import { EventType, PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { App } from "./App";
import "./App.css";
import "./index.css";
import { msalConfig } from "./pages/user/ab2cAuthProvider";
import reportWebVitals from "./reportWebVitals";
import { Metadata } from "./types/Metadata";
import { Answer, ParticipantAnswer, ProblemAnswer, Report, ReviewComment } from "./types/Rows";
import './helpers/i18n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    (event.payload as any).account
  ) {
    msalInstance.setActiveAccount((event.payload as any).account);
  }
});

// msalInstance.initialize();
// msalInstance.initialize();
export type NavigationBadge = {
  text: string;
  icon: string;
  url: string;
  links: [{ name: string; value: string }];
};

export const useNavigationBadgesStore = create((set) => ({
  navigationBadges: [],
  setItems: (items: NavigationBadge[]) =>
    set((state: any) => ({
      navigationBadges: [...items],
    })),
}));

export const useSectionQuestions = create(
  combine({ questionAnswers: new Map<string, Answer>() }, (set) => ({
    updateQuestionAnswer: (answer: Answer) =>
      set((state) => {
        state.questionAnswers.set(answer.questionId, answer);
        return { questionAnswers: state.questionAnswers };
      }),
    clear: () =>
      set((state) => {
        return { questionAnswers: new Map<string, Answer>() };
      }),
  }))
);

export const useParticipantAnswers = create(
  combine(
    { participantAnswers: new Map<string, ParticipantAnswer>() },
    (set) => ({
      updateParticipantAnswer: (answer: ParticipantAnswer) =>
        set((state) => {
          state.participantAnswers.set(answer.id, answer);
          return { participantAnswers: state.participantAnswers };
        }),
      clear: () =>
        set((state) => {
          return { participantAnswers: new Map<string, ParticipantAnswer>() };
        }),
    })
  )
);

export const useProblemAnswers = create(
  combine({ problemAnswers: new Map<string, ProblemAnswer>() }, (set) => ({
    updateProblemAnswer: (answer: ProblemAnswer) =>
      set((state) => {
        state.problemAnswers.set(answer.answerId, answer);
        return { problemAnswers: state.problemAnswers };
      }),
    clear: () =>
      set((state) => {
        return { problemAnswers: new Map<string, ProblemAnswer>() };
      }),
  }))
);

export const useMetadata = create(
  combine({ metadata: {} as Metadata }, (set) => ({
    updateMetadata: (update: (metadata: Metadata) => Metadata) =>
      set((state) => {
        let newMetadata = Object.assign({}, state.metadata) as Metadata;
        let md = update(newMetadata);
        return { metadata: md };
      }),
    clear: () =>
      set((state) => {
        return { metadata: {} as Metadata };
      }),
  }))
);

export const useReport = create(
  combine({ report: {} as Report }, (set) => ({
    update: (report: Report) =>
      set((state) => {
        return { report: report };
      }),
    clear: () =>
      set((state) => {
        return { report: {} as Report };
      }),
  }))
);

export const useDiscussion = create(
  combine({ discussion: new Map<string, ReviewComment[]>() }, (set) => ({
    update: (discussion: Map<string, ReviewComment[]>) =>
      set((state) => {
        return { discussion: discussion};
      }),
    clear: () =>
      set((state) => {
        return { discussion:new Map<string, ReviewComment[]>() };
      }),
  }))
);

root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <App instance={msalInstance} /> {/* </BrowserRouter> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
