import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { getAllQuestions, getTemplateProblems } from "../../api/questionsApi";
import { GenericItemsListPage } from "../questions/genericItemsListPage";
import { QuestionDTO } from "../../types/Rows";

export const IssuesPage = () =>{ 
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);
    const [mappedItems, setMappedItems] = useState<QuestionDTO[]>([]);
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["Problems"],
    () => getTemplateProblems(),
  );

  useEffect(() => {
    if (data) {
      setItems([{ text: "Issues", url: "/Questions" } as NavigationBadge]);
      const mapped = data.categories.map(x=> ({
        id: x.id.toString(), 
        description: x.value, 
        name: x.value, 
        answers:[],  } as QuestionDTO));

        setMappedItems(mapped);
    }
  }, [data, setItems]);
 
  return (<GenericItemsListPage items={mappedItems} isFetching={isFetching} urlPrefix="issues/" /> )
};
