import { Textarea } from "@material-tailwind/react";

export function TextAreaInput   ({
    value, 
    onChange, label,
    maxLength = 30,
    maxWidth = true
}: 
    {value:string, onChange:(val)=>void, label:string, maxLength?: number, maxWidth?: boolean }) {
    return (
      <div className={maxWidth ? `w-72` : ""
      }>
        <Textarea        
            maxLength={maxLength}
            // className="min-h-full"
            rows={3}
            // resize={true}
             label={label} onChange={(e)=> onChange(e.target.value) }
            value={value} />
      </div>
    );
  }

  export function TextAreaInputShort   ({
    value, 
    onChange, label,
    maxLength = 30,
    rowSize = 3,
    maxWidth = true
}: 
    {value:string, onChange:(val)=>void, label:string, maxLength?: number, maxWidth?: boolean, rowSize?: number }) {
    return (
      <div className="w-60" 
      >
        <Textarea        
            maxLength={maxLength}
            className="min-h-full"
            rows={rowSize}
            resize={true}
             label={label} onChange={(e)=> onChange(e.target.value) }
            value={value} />
      </div>
    );
  }