import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getQuestion } from "../../api/questionsApi";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { useEffect, useState } from "react";
import ItemsList from "../../components/lists/ItemsList";
import { BaseDto } from "../../types/Rows";

export const QuestionPage = () => {
  let { questionId } = useParams();
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  const { data, isFetching, error, refetch } = useQuery(
    ["Question", questionId!],
    () => getQuestion(questionId!),
    {}
  );

  useEffect(() => {
    if (data) {
      setItems([
        { text: "Pytania", url: "/Questions" } as NavigationBadge,
        {
          text: data.description.substring(0, 40) + "...",
          url: "Questions/" + data.id,
        } as NavigationBadge,
      ]);
    }
  }, [data, setItems]);

  if (isFetching) return <>Loading</>;

  return (
    <div className="App">
      <div className="p-5 flex flex-col gap-5">
        <div>
          <div className="flex content-between justify-between">
            <div>
              <h1 className="text-3xl font-extrabold dark:text-white">
                {data.description}
              </h1>
            </div>
          </div>
        </div>
        <ItemsList
          isLoading={isFetching}
          data={data.answers.map(
            (x) =>
              ({
                name:
                  x.resortName +
                  ", " +
                  x.reportName +
                  " - " +
                  (x.answer == "yes" ? "tak" : "nie"),
                id: x.reportId,
                created: x.created,
                createdBy: x.createdBy,
                description: x.comment,
              } as BaseDto)
          )}
          urlPrefix="/reports/"
          isSortVisible
        >
          <header>
            <div className="max-w-7xl">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Odopowiedzi
              </h1>
            </div>
          </header>{" "}
        </ItemsList>
      </div>
    </div>
  );
};
