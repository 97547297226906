import classNames from "classnames";
import { ALL_TIME } from "../constants";
import { ListBoxOption } from "./ListBox";
import { Job, StoryType } from "./types";
import { dateTimeString, getDomain } from "./utils";
import { BaseDto, Study } from "../../types/Rows";
import { formatDateTime } from "./formatDate";

export default function StoryView({
  showCompleteStory = false,
  currentFilter = ALL_TIME,
  data,
  urlPrefix,
  additionalInfoFactory = (data: any) => null,
}: {
  showCompleteStory?: boolean;
  currentFilter?: ListBoxOption;
  data: Study;
  urlPrefix: string;
  additionalInfoFactory?: (data: any) => any;
}) {
  return (
    <>
      <div id={`story-${data.id}`} className="flex justify-between">
        <div>
          <a
            className="block cursor-pointer"
            href={urlPrefix + data.id}

            // onClick={() => openStory()}
          >
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {data.name}
            </h3>
            <article
              className="mt-1 text-base leading-6 text-gray-500 "
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </a>
          <div className="flex items-center mt-1">
            <div>
              <div className="flex text-sm leading-5 text-gray-500">
                <a
                  href={``}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="flex items-center text-sm font-medium leading-5 text-gray-700 cursor-pointer"
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="ml-1 hover:underline">{data.createdBy}</p>
                </a>
                <span className="mx-1">&middot;</span>
                {/* <time dateTime={new Date(data.created)}> */}
                Utworzono {formatDateTime(data.created)}
                {/* </time>                 {data.edited ? ` Edytowano ${new Date(data.created).toLocaleTimeString()} ` : ""}
                 */}
              </div>
            </div>
          </div>
        </div>
        <div>{additionalInfoFactory(data)}</div>
      </div>
    </>
  );
}

export function BaseItemView({
  showCompleteStory = false,
  currentFilter = ALL_TIME,
  data,
}: {
  showCompleteStory?: boolean;
  currentFilter?: ListBoxOption;
  data: BaseDto;
}) {
  // const story = data as unknown as Study | Job;
  // const storyType: StoryType =
  //   story.type === "job"
  //     ? StoryType.JOB
  //     : story.url
  //     ? StoryType.SHOW
  //     : StoryType.ASK;

  return (
    <>
      <div id={`story-${data.id}`}>
        <a
          className="block cursor-pointer"
          href={"reports/" + data.id} // TODO - get also prefix for href

          // onClick={() => openStory()}
        >
          <h3 className="mt-4 text-xl font-semibold leading-7 text-gray-900">
            {data.name}
          </h3>
        </a>
        <div className="flex items-center mt-1">
          <div>
            <div className="flex text-sm leading-5 text-gray-500">
              <a
                href={``}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="flex items-center text-sm font-medium leading-5 text-gray-700 cursor-pointer"
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="ml-1 hover:underline">{data.createdBy}</p>
              </a>
              <span className="mx-1">&middot;</span>
              {/* <time dateTime={new Date(data.created)}> */}
              Utworzono {formatDateTime(data.created)}
              {/* </time>                 {data.edited ? ` Edytowano ${new Date(data.created).toLocaleTimeString()} ` : ""}
               */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
