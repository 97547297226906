import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { ReportFile } from "../../../types/Rows";
import { deleteFile, GetReportFile } from "../../../api/reportsApi";
import FileUpload from "./FileUploadt";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function ReportFiles({
  files,
  reportId,
}: {
  files: ReportFile[];
  reportId: string;
}) {
  return (
    <div>
      <div className="text-xl text-center mt-1">Pliki</div>

      <div className="flex flex-wrap gap-2 px-2">
        {files.map((file) => (
          <SimpleCard name={file.name} id={file.id} reportId={reportId} />
        ))}
      </div>
      <div className="flex flex-row-reverse p-5">
        <FileUpload reportId={reportId} />
      </div>
    </div>
  );
}

export function SimpleCard({ name, id, reportId }) {
  const { mutate: deleteItem } = useMutation(
    async ({ fileId }: { fileId: string }) => {
      await deleteFile(reportId, fileId);
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([reportId, "Files"]);
      },
    }
  );

  const queryClient = useQueryClient();

  return (
    <Card className="mt-6 w-96" variant="filled">
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          {name}
        </Typography>
      </CardBody>
      <CardFooter className="pt-0 px-1 flex justify-evenly">
        <Button
          variant="text"
          onClick={async () => {
            await deleteItem({ fileId: id });
          }}
        >
          Usuń
        </Button>
        <Button
          variant="outlined"
          onClick={async () => {
            const f = await GetReportFile(id, id);

            const url = URL.createObjectURL(
              new Blob([f], { type: "application/pdf" })
            );
            window.open(url, "_blank"); // Open the merged PDF in a new window/tab
          }}
        >
          Pobierz
        </Button>
      </CardFooter>
    </Card>
  );
}
