import { useEffect, useState } from "react";
import { BaseDto, EditableReportQuestion } from "../../../types/Rows";
import { useTemplateContext } from "../templateContext";
import { Question } from "./Question";

export const Row = ({
  templateId,
  input,
  index,
  sectionId,
  isLocked,
  depth,
}: {
  templateId: string;
  index: number;
  input: EditableReportQuestion;
  sectionId: string;
  isLocked: boolean;
  depth: number;
}) => {
  // load previous answers for a given row
  const { updateQuestion } = useTemplateContext();

  const [description, setDescription] = useState<string>(input?.description);
  const [isBlank, setIsBlank] = useState<boolean>(input.isBlank);
  const [childrenRequiredAnswer, setChildrenRequiredAnswer] = useState(
    input.requiredAnswerToBeVisible
  );

  useEffect(() => {
    updateQuestion({
      ...input,
      description: description,
      isBlank: isBlank,
      requiredAnswerToBeVisible: childrenRequiredAnswer,
    } as EditableReportQuestion);
  }, [description, isBlank, childrenRequiredAnswer]);

  return (
    <Question
      index={index + 1}
      input={input}
      childrenRequiredAnswer={childrenRequiredAnswer}
      setChildrenRequiredAnswer={setChildrenRequiredAnswer}
      description={description}
      setDescription={setDescription}
      templateId={templateId}
      isBlank={isBlank}
      setIsBlank={setIsBlank}
      sectionId={sectionId}
      isLocked={isLocked}
      depth={depth}
    />
  );
};
