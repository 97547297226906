import { Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Input } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";

import MagnifyingGlass from "../../assets/icons/MagnifyingGlass";
const defaultStyle = "";

const Modal = ({
  headerTitle,
  setShowModal,
  saveItem,
  saveExisting,
  existingItems,
  inputType,
}: {
  headerTitle: string;
  setShowModal: any;
  saveItem: (name: string) => void;
  saveExisting: (questionId: string) => void;
  existingItems?: { name: string; id: string }[];
  inputType: InputType;
}) => {
  const [value, setValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<
    { name: string; id: string }[]
  >([]);

  const onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredItems(
      existingItems?.filter(({ name }) =>
        name.toLocaleLowerCase().includes(target.value.toLocaleLowerCase())
      ) || []
    );
  };

  useEffect(() => {
    setFilteredItems(existingItems);
  }, [existingItems]);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">{headerTitle}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="text-black w-6 text-2xl block outline-none focus:outline-none">
                  x
                </span>
              </button>
            </div>
            {/*body*/}
            <div>
              <Tabs.Group
                aria-label="Pills"
                style="pills"
                className="self-center"
              >
                // TODO - Hide
                <Tabs.Item
                  active={
                    inputType == InputType.SINGLE ||
                    inputType == InputType.ALLOWALL
                  }
                  hidden={inputType == InputType.PICKFROMEXISTING}
                  disabled={inputType == InputType.PICKFROMEXISTING}
                  title="Nowy"
                >
                  <div className="relative flex-auto">
                    <textarea
                      style={{ height: "200px", width: "600px" }}
                      className="border"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center justify-end p-6 border-solid border-slate-200 rounded-b">
                    <button
                      className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Zamknij
                    </button>
                    <button
                      className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        saveItem(value);
                        setShowModal(false);
                      }}
                    >
                      Zapisz
                    </button>
                  </div>
                </Tabs.Item>
                <Tabs.Item
                  active={inputType == InputType.PICKFROMEXISTING}
                  disabled={inputType == InputType.SINGLE}
                  title="Wybierz z istniejących"
                >
                  <div
                    className="relative flex-auto overflow-x-auto p-5 pt-0"
                    style={{ height: "200px", width: "600px" }}
                  >
                    <Input
                      containerProps={{ className: "my-2" }}
                      icon={<MagnifyingGlass />}
                      variant="outlined"
                      type="search"
                      label="Wyszukaj"
                      onChange={onSearchChange}
                    />
                    <dl
                      className="text-gray-900 divide-y divide-gray-200
                     dark:text-white dark:divide-gray-700 "
                    >
                      {filteredItems.map((item) => (
                        <div
                          onClick={() => {
                            setShowModal(false);
                            saveExisting(item.id);
                          }}
                          className="flex flex-col pb-3 hover:bg-gray-100 cursor-pointer"
                        >
                          {/* <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                            Email address
                          </dt> */}
                          <dd className="text-lg font-semibold">{item.name}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>{" "}
                </Tabs.Item>
              </Tabs.Group>
            </div>
            {/*footer*/}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export enum InputType {
  SINGLE,
  PICKFROMEXISTING,
  ALLOWALL,
}

export default function AddItemWithExistingPicker({
  saveNew,
  text,
  children,
  saveExisting,
  items, // TODO - remove
  inputType = InputType.ALLOWALL,
  buttonStyle = defaultStyle,
}: {
  text: string;
  saveNew: (name: string) => void;
  saveExisting: (questionId: string) => void;
  children?: any;
  items: () => Promise<{ name: string; id: string }[]>;
  inputType?: InputType;
  buttonStyle?: string;
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [existingItems, setExistingItems] = useState<any[]>([]);

  useEffect(() => {
    if (showModal) {
      items().then((result) => {
        console.log("result is", result);
        setExistingItems(result);
      });
    }
  }, [showModal, items]);

  return (
    <>
      <Button
        size="sm"
        className={buttonStyle}
        variant="outlined"
        onClick={() => setShowModal(true)}
      >
        {children}
      </Button>
      {showModal && (
        <Modal
          headerTitle={text}
          saveItem={saveNew}
          setShowModal={setShowModal}
          saveExisting={saveExisting}
          existingItems={existingItems}
          inputType={inputType}
        />
      )}

      {/* {showModal && dropdownType == DropdownType.DROPDOWNS ? (
        <Modal
          headerTitle={text}
          saveItem={saveItem}
          setShowModal={setShowModal}
        />
      ) : null} */}
    </>
  );
}
