import { useState } from "react";
import { Metadata } from "../../../types/Metadata";
import { useMetadata } from "../../..";
import { TextAreaInput } from "../../../components/atomic/commentsInput";

export const FinalComment = ({ metadata }: { metadata: Metadata }) => {
  const [finalComment, setFinalComment] = useState<string>(
    metadata.finalComment
  );

  const { updateMetadata } = useMetadata();

  return (
    <>
      <div className="sm:rounded-lg">
        <div
          className="grid grid-cols-2
          p-5
              divide-x gap-1"
        >
          <div className="col-span-2">
            <TextAreaInput
              label="Komentarz końcowy"
              maxWidth={false}
              maxLength={2000}
              value={finalComment}
              onChange={(e) => {
                setFinalComment(e);
                updateMetadata((metadata: Metadata) => {
                  metadata.finalComment = e;
                  return metadata;
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
