import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addQuestion } from "../../../api/reportsApi";
import { lockToast } from "../../../helpers/successToast";
import { PlusIcon } from "../../../types/PlusIcon";
import {
  EditableReportQuestion,
  TemplateEditableSection,
} from "../../../types/Rows";
import AddItemWithExistingPicker from "../AddItemWithExistingPicker";
import { SectionEdit } from "./SectionEdit";

export const EditableSectionsList = ({
  templateId,
  sections,
  existingItems,
  saveExisting,
  isLocked,
}: {
  templateId: string;
  sections: TemplateEditableSection[];
  existingItems: EditableReportQuestion[];
  saveExisting: (id: string, questionId: string) => void;
  isLocked: boolean;
}) => {
  const queryClient = useQueryClient();
  const addItem = useMutation(
    ({
      sectionId,
      question,
    }: {
      sectionId: string;
      question: { description: string; weight: number };
    }) => addQuestion(templateId, sectionId, question),
    {
      onSuccess: () => {},
    }
  );
  return (
    <div className="flex flex-col gap-2">
      {sections
        .sort((a, b) => (a.weight < b.weight ? -1 : 1))
        .map((section, i) => (
          <>
            <SectionEdit
              key={section.id}
              section={section}
              items={section.questions}
              templateId={templateId}
              index={i}
              isLocked={isLocked}
            />
            <div className="self-end">
              <AddItemWithExistingPicker
                text="Dodaj pytanie"
                saveNew={(value) => {
                  if (isLocked) {
                    lockToast();
                    return;
                  } //test
                  addItem.mutate(
                    {
                      sectionId: section.id,
                      question: {
                        description: value,
                        weight: section.questions.length + 1,
                      },
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(["Templates"]);
                      },
                    }
                  );
                }}
                saveExisting={(questinoId: string) => {
                  if (isLocked) {
                    lockToast();
                    return;
                  }
                  return saveExisting(section.id, questinoId);
                }}
                items={() =>
                  Promise.resolve(
                    existingItems.map((x) => ({
                      id: x.id,
                      name: x.description,
                    })) ?? []
                  )
                }
              >
                <PlusIcon />{" "}
              </AddItemWithExistingPicker>
            </div>
          </>
        ))}
    </div>
  );
};
