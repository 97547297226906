const valsMap = new Map<string, number>([
  ["new", 1],
  ["ongoing", 2],
  ["finished", 3],
]);

const cmp = function (a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

export const sortIssues = (items) =>
  items?.sort((a, b) => {
    if ((valsMap.get(a.answer) ?? 0) < (valsMap.get(b.answer) ?? 0)) return -1;
    if ((valsMap.get(a.answer) ?? 0) > (valsMap.get(b.answer) ?? 0)) return 1;

    return cmp(a.created, b.created);
  });
