import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useEffect, useRef, useState } from "react";
import {
  formatDate,
  formatDateNumbers,
} from "../../../components/lists/formatDate";
import { Metadata } from "../../../types/Metadata";
import {
  Answer,
  ParticipantAnswer,
  ProblemAnswer,
  RaportSection,
  TemplateType,
} from "../../../types/Rows";
import { ItemPopover } from "../../../components/atomic/itemPopover";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: 300,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    // flexDirection: "column",
    fontSize: 12,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "white",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    // alignContent:'space-between',
    gap: 5,
  },
  columsSection: {
    margin: 5,
    flexGrow: 1,
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  borderedItem: {
    borderBottom: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    minWidth: "25%",
    // textOverflow:'ellipsis'
  },
  nonBorderedItem: {
    minWidth: "25%",
    // textOverflow:'ellipsis'
  },
  borderedItemSlid: {
    padding: 2,
    borderBottom: 1,
    borderColor: "black",
    borderStyle: "solid",
  },
  title: {
    fontSize: 20,
    fontWeight: "semibold",
    marginBottom: 10,
    textAlign: "center",
  },
  section: {
    marginBottom: 5,
    marginTop: 5,
    fontSize: 10,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "semibold",
  },
  importantParagraph: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 10,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const statusName = (val?: string) => {
  if (val === "new") return "Nowe";
  else if (val === "ongoing") return "Trwające";
  if (val === "finished") return "Zakończone";
  else return "Brak statusu";
};

const getRadioResult = (isNoAnswer: boolean, val?: string) => {
  if (isNoAnswer) return "";
  if (val === "yes") return "TAK";
  else if (val === "no") return "NIE";
  else if (val === "na") return "NA";
  else return " ";
};

const capitalizeWords = (str) =>
  str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");

const getBoolTextVal = (val: boolean) => {
  return val ? "TAK" : "NIE";
};

const groupByToMap = <T, Q>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => Q
) =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());

export const DocumentGenerator = ({
  reportName,
  resortName,
  address,
  metadata,
  problemAnswers,
  sections,
  participants,
  types,
  footer,
  author,
  composedDate,
}: {
  reportName: string;
  address: string;
  resortName: string;
  metadata: Metadata;
  problemAnswers: ProblemAnswer[];
  sections: RaportSection[];
  participants: ParticipantAnswer[];
  types: TemplateType[];
  footer: string;
  author: string;
  composedDate: Date;
}) => {
  const [questionsCopy, setQuestionsCopy] = useState<Map<string, Answer>>(
    new Map<string, Answer>()
  );
  useEffect(() => {
    let questionsCopy = new Map<string, Answer>();
    sections
      .flatMap((x) => x.questionsWithAnswer)
      .forEach((q) => questionsCopy.set(q.questionId, q));

    sections
      .flatMap((x) => x.questionsWithAnswer)
      .sort((x) => (x.parentId == null ? -1 : 1))
      .forEach((element) => {
        if (element.parentId && questionsCopy.has(element.parentId)) {
          const val = questionsCopy.get(element.parentId);
          val.children = val.children
            ? [
                ...val.children.filter(
                  (x) => x.questionId != element.questionId
                ),
                element,
              ]
            : [element];
        }
      });

    setQuestionsCopy(questionsCopy);
  }, [sections]);

  const isChildren = (item: Answer) => {
    let parent = questionsCopy.has(item.parentId);
    if (parent || item.requiredAnswerToBeVisible != null) {
      return true;
    }
    return false;
  };

  const QuestionChildren = ({
    answer,
    index,
    questionIndex,
    childIndex,
  }: {
    answer: Answer;
    index: number;
    questionIndex: number;
    childIndex?: number;
  }) => {
    return (
      <>
        {answer.children
          ?.filter(
            (x) =>
              answer.answer.toLowerCase() ==
              x?.requiredAnswerToBeVisible?.toLowerCase()
          )
          ?.sort((a, b) => (new Date(a.created) > new Date(b.created) ? 1 : -1))
          .map((childQ, childQIndex) => (
            <>
              <View
                style={{
                  borderTop: "solid",
                  borderLeft: "solid",
                  borderLeftWidth: "1px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderTopWidth: "1px",
                }}
              >
                <Text style={{ maxWidth: "94%", paddingLeft: "2px" }}>
                  {index + 1}.{questionIndex + 1}.
                  {/* awful way to make numbers under children work */}
                  {childIndex ? childIndex + "." : ""}
                  {childQIndex + 1}. {childQ.description}
                </Text>
                <View
                  style={{
                    borderLeft: "solid",
                    borderLeftWidth: "1px",
                    borderRight: "solid",
                    borderRightWidth: "1px",
                    width: "5%",
                  }}
                >
                  <Text
                    style={{
                      paddingLeft: 2,
                      marginRight: 2,
                    }}
                  >
                    {getRadioResult(childQ.isBlank, childQ.answer ?? "")}
                  </Text>
                </View>
              </View>
              <QuestionChildren
                answer={childQ}
                index={index}
                questionIndex={questionIndex}
                childIndex={childQIndex + 1}
              />
            </>
          ))}
      </>
    );
  };

  const ChildrenComments = ({
    answer,
    index,
    questionIndex,
    childIndex,
  }: {
    answer: Answer;
    index: number;
    questionIndex: number;
    childIndex?: number;
  }) => {
    return (
      <View>
        {answer.children
          ?.filter(
            (x) =>
              answer.answer.toLowerCase() ==
              x?.requiredAnswerToBeVisible?.toLowerCase()
          )
          ?.sort((a, b) => (new Date(a.created) > new Date(b.created) ? 1 : -1))
          .map((childQ, childQIndex) => (
            <>
              {childQ.comment && (
                <Text>
                  {index + 1}.{questionIndex + 1}.
                  {/* awful way to make numbers under children work */}
                  {childIndex ? childIndex + "." : ""}
                  {childQIndex + 1}. {childQ.comment}
                </Text>
              )}
              <ChildrenComments
                answer={childQ}
                index={index}
                questionIndex={questionIndex}
                childIndex={childQIndex + 1}
              />
            </>
          ))}
        ;
      </View>
    );
  };

  const QuestionsParagraph = () => {
    return (
      <View>
        <Text style={styles.importantParagraph}>PYTANIA</Text>
        {sections
          .sort((a, b) => (a.weight < b.weight ? -1 : 1))
          .map((section, index) => (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>
                {index + 1} {section.name}:
              </Text>
              <View
                style={{
                  borderBottom: "solid",
                  borderBottomWidth: "1px",
                }}
              >
                {section.questionsWithAnswer
                  .filter((x) => !isChildren(x))
                  .sort((a, b) => (a.weight < b.weight ? -1 : 1))
                  .map((q, questionIndex) => (
                    <>
                      <View
                        wrap={false}
                        style={{
                          borderTop: "solid",
                          borderLeft: "solid",
                          borderLeftWidth: "1px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderTopWidth: "1px",
                        }}
                      >
                        <Text style={{ maxWidth: "94%", paddingLeft: "2px" }}>
                          {index + 1}.{questionIndex + 1}. {q.description}
                        </Text>
                        <View
                          style={{
                            borderLeft: "solid",
                            borderLeftWidth: "1px",
                            borderRight: "solid",
                            borderRightWidth: "1px",
                            width: "5%",
                          }}
                        >
                          <Text
                            style={{
                              paddingLeft: 2,
                              marginRight: 2,
                            }}
                          >
                            {getRadioResult(q.isBlank, q.answer ?? "")}
                          </Text>
                        </View>
                      </View>
                      <QuestionChildren
                        answer={q}
                        index={index}
                        questionIndex={questionIndex}
                      />
                    </>
                  ))}
              </View>
              <View>
                <Text>Komentarze:</Text>
              </View>
              {section.questionsWithAnswer
                .filter((x) => !isChildren(x))
                .sort((a, b) => (a.weight < b.weight ? -1 : 1))
                .map((q, questionIndex) => (
                  <View>
                    {q.comment && (
                      <Text>
                        {index + 1}.{questionIndex + 1}. {q.comment}
                      </Text>
                    )}
                    <ChildrenComments
                      answer={q}
                      index={index}
                      questionIndex={questionIndex}
                    />
                  </View>
                ))}

              {/* {section.questionsWithAnswer.length > 0 && (
                <View>
                  <Text>Komentarze:</Text>
                  {section.questionsWithAnswer
                    .sort((a, b) => (a.weight < b.weight ? -1 : 1))
                    .filter((x) => !isChildren(x))
                    .map((q, questionIndex) => (
                      <View>
                        {q.comment && (
                          <Text>
                            {index + 1}.{questionIndex + 1}. {q.comment}
                          </Text>
                        )}
                        {q.children?.map((child, childIndex) => (
                          <>
                            {child.comment && (
                              <Text>
                                {index + 1}.{questionIndex + 1}.{childIndex + 1}
                                . {child.comment}
                              </Text>
                            )}

                            {child.children?.map(
                              (childChildren, childChildrenIndex) => (
                                <>
                                  {childChildren.comment && (
                                    <Text>
                                      {index + 1}.{questionIndex + 1}.
                                      {childIndex + 1}.{childChildrenIndex + 1}.{" "}
                                      {childChildren.comment}
                                    </Text>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ))}
                      </View>
                    ))}
                </View>
              )} */}
            </View>
          ))}
      </View>
    );
  };

  const MetaData = () => {
    return (
      <View>
        {" "}
        <View>
          <Text style={styles.importantParagraph}>Uczestnicy</Text>
          <View
            style={{ flexWrap: "wrap", display: "flex", flexDirection: "row" }}
          >
            <Text style={{ fontWeight: "bold" }}>CRO: </Text>{" "}
            <Text>{metadata.croParticipants}</Text>
          </View>
          <View
            style={{ flexWrap: "wrap", display: "flex", flexDirection: "row" }}
          >
            {" "}
            <Text style={{ fontWeight: "bold", paddingTop: 2 }}>Ośrodek: </Text>
            <Text>{metadata.resortParticipants}</Text>
          </View>
        </View>
        <View style={styles.columsSection}>
          <Text style={styles.importantParagraph}>
            Status rekrutacji: {metadata.recrutationStatus}%
          </Text>
          <Text style={styles.importantParagraph}>
            Odchylenia od protokołu: {metadata?.protocolDeviation}
          </Text>
          <Text style={styles.importantParagraph}>
            SAE: {metadata?.reportedSAE}
          </Text>
        </View>
        <View style={styles.flexWrap}>
          {metadata.declaredParticipants != null && (
            <Text style={styles.nonBorderedItem}>
              Zadeklarowani: {metadata?.declaredParticipants}
            </Text>
          )}
          {metadata.duringTreatment != null && (
            <Text style={styles.nonBorderedItem}>
              W trakcie leczenia: {metadata?.duringTreatment}
            </Text>
          )}
          {metadata.afterScreening != null && (
            <Text style={styles.nonBorderedItem}>
              Skriningowani: {metadata?.afterScreening}
            </Text>
          )}
          {metadata?.randomized != null && (
            <Text style={styles.nonBorderedItem}>
              Zrandomizowani: {metadata?.randomized}
            </Text>
          )}
          {metadata?.prematureCompletion != null && (
            <Text style={styles.nonBorderedItem}>
              Kończący przedwcześnie EOT : {metadata?.prematureCompletion}
            </Text>
          )}
          {metadata?.screenFailure != null && (
            <Text style={styles.nonBorderedItem}>
              Z niepowodzeniem skriningu: {metadata?.screenFailure}
            </Text>
          )}
          {metadata?.duringFollowUp != null && (
            <Text style={styles.nonBorderedItem}>
              W trakcie obserwacji: {metadata?.duringFollowUp}
            </Text>
          )}

          {metadata?.completedStudy != null && (
            <Text style={styles.nonBorderedItem}>
              Zakończony udział EOS : {metadata?.completedStudy}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const Issues = () => {
    let counter = 0;
    return (
      <View>
        <Text style={styles.importantParagraph}>Lista znalezisk</Text>
        {Array.from(groupByToMap(problemAnswers, (x) => x.answer)).map(
          (group, groupIndex) => (
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                {statusName(group[0])}:
              </Text>
              <View style={{ fontSize: 10 }}>
                {group[1].map((problem, index) => {
                  counter++;
                  return (
                    <View style={styles.borderedItem} wrap={false}>
                      <View
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <View style={{ width: "40%" }}>
                          <Text>{counter}.{" "}{problem.category}</Text>
                        </View>
                        <View style={{ width: "55%" }}>
                          <Text>{problem?.description ?? ""}</Text>
                        </View>
                      </View>

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          color: "gray",
                        }}
                      >
                        <Text>
                          Data rozwiązania{" "}
                          {formatDateNumbers(problem?.resolvedDate)}
                        </Text>
                        <Text>
                          Zidentyfikowano{" "}
                          {formatDateNumbers(problem?.identificationDate)}
                        </Text>
                        <Text>Przez {problem.personIdentifying ?? ""}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )
        )}
      </View>
    );
  };

  const Participants = () => {
    return (
      <View>
        <Text style={styles.importantParagraph}>
          ZAKRES MONITOROWANYCH DANYCH
        </Text>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "solid",
              borderBottomWidth: "1px",
              fontSize: 10,
            }}
          >
            <Text style={{ width: "25%" }}>Nr uczestnika</Text>
            <Text style={{ width: "25%" }}>Nr zweryfikowanej wizyty</Text>
            <Text style={{ width: "25%" }}>100%</Text>
            <Text style={{ width: "25%" }}>RBV</Text>
          </View>
          {participants.map((participant, index) => (
            <View style={styles.borderedItemSlid}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 10,
                }}
              >
                <Text style={{ width: "25%" }}>
                  {index + 1}. {participant.participantNumber}
                </Text>
                <Text style={{ width: "25%" }}>{participant.visitNumber}</Text>
                <Text style={{ width: "25%" }}>
                  {getBoolTextVal(participant.isFinshed)}
                </Text>
                <Text style={{ width: "25%" }}>
                  {getBoolTextVal(participant.isVerified)}
                </Text>
              </View>
            </View>
          ))}

          <View style={{ fontWeight: "light", fontSize: 10 }}>
            <Text>Komentarze:</Text>
            {participants.map(
              (participant, index) =>
                participant.comment && (
                  <Text>
                    {index + 1}. {participant.comment}{" "}
                  </Text>
                )
            )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.title}>
            {reportName} w {resortName}
          </Text>
          {types.find((x) => x == TemplateType.METADATA) && (
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                fontSize: 10,
                gap: 10,
                color: "gray",
              }}
            >
              <Text>
                Numer Protokołu:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {metadata.protocolNumber}{" "}
                </Text>
              </Text>
              <Text>
                Akronim Badania:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {metadata.studyAcronym}
                </Text>
              </Text>
              <Text>
                Numer Ośrodka:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {metadata.resortNumber}
                </Text>
              </Text>
              <Text>
                Główny badacz:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {metadata.mainResearcher}
                </Text>
              </Text>
              <Text>
                {metadata.visitNumber ? "Numer Wizyty: " : "Typ Wizyty: "}
                <Text style={{ fontWeight: "bold" }}>
                  {metadata.visitNumber != 0 && metadata.visitNumber != null
                    ? metadata.visitNumber
                    : metadata.visitType}
                </Text>
              </Text>
              <Text>
                Data wizyty:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {formatDate(metadata.visitDate)}
                </Text>
              </Text>
              <Text>Adres: {address} </Text>
            </View>
          )}
          {types.find((x) => x == TemplateType.STATUS) && <MetaData />}
          <View
            style={{
              borderBottom: "1px",
              borderColor: "#e0e0e0",
              paddingTop: "5",
            }}
          ></View>
          {types.find((x) => x == TemplateType.ISSUES) && <Issues />}

          {types.find((x) => x == TemplateType.PARTICIPANTS) && (
            <Participants />
          )}
        </View>

        {types.find((x) => x == TemplateType.QUESTIONS) && (
          <QuestionsParagraph />
        )}

        {types.find((x) => x == TemplateType.FINALCOMMENT) && (
          <View>
            <Text>Komentarz końcowy:</Text>
            <Text>{metadata.finalComment}</Text>
          </View>
        )}

        <View
          wrap={false}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            marginRight: 20,
            marginTop: 40,
          }}
        >
          <View
            style={{
              flex: 1,
              width: "30%",
              fontWeight: "bold",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text> </Text>
            <View
              style={{
                width: "100%",
                borderTop: "1px",
                borderStyle: "solid",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <Text>DATA SPORZĄDZENIA:</Text>
              <Text>{new Date(composedDate).toLocaleDateString("pl-PL")}</Text>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              width: "30%",
              fontWeight: "bold",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text> </Text>
            <View
              style={{
                width: "100%",
                borderTop: "1px",
                borderStyle: "solid",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <Text>IMIĘ I NAZWISKO MONITORA (CRA):</Text>
              <Text>{capitalizeWords(author)}</Text>
            </View>
          </View>
        </View>

        {types.find((x) => x == TemplateType.FOOTER) && (
          <View
            style={{
              position: "absolute",
              fontSize: 12,
              bottom: 50,
              left: 0,
              right: 0,
              textAlign: "center",
              color: "grey",
            }}
          >
            <Text>{footer}</Text>
          </View>
        )}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

/* const QuestionsParagraph = () => {
    return (
      <View style={styles.section}>
        <Text style={styles.importantParagraph}>PYTANIA</Text>
        {sections.map((section, index) => (
          <View style={{ margin: 5 }}>
            <Text style={styles.sectionTitle}>
              {index + 1} {section.name}:
            </Text>
            {section.questionsWithAnswer.map((q, questionIndex) => (
              <View>
                <Text>
                  {index + 1}.{questionIndex + 1} {q.description} -{" "}
                  <Text
                    style={{
                      fontWeight: "semibold",
                      color: getRadioResultColor(q?.answer ?? ""),
                    }}
                  >
                    {getRadioResult(q.answer ?? "")}
                  </Text>
                </Text>
              </View>
            ))}

            <View style={{ fontWeight: "light" }}>
              <Text>Komentarze:</Text>
              {section.questionsWithAnswer
                .filter((x) => x.comment)
                .map((q, questionIndex) => (
                  <View>
                    <Text>
                      {index + 1}.{questionIndex + 1} {q.comment}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
        ))}
      </View>
    );
  };

  */
