import { useEffect, useState } from "react";
import { useSectionQuestions } from "../../..";
import { Answer } from "../../../types/Rows";
import { QuestionWithAnswer } from "./QuestionWithAnswer";

export const Row = ({
  input,
  reportId,
  index,
  depth = 0,
}: {
  input: Answer;
  index: string;
  reportId: string;
  depth: number;
}) => {
  const [comment, setComment] = useState<string>(input.comment);
  const [answer, setAnswer] = useState<string>(input.answer);
  const updateQuestionAnswer = useSectionQuestions(
    (state) => state.updateQuestionAnswer
  );

  const [children, setChildren] = useState<Answer[]>([]);

  useEffect(() => {
    if (input.children) {
      setChildren(input.children);
    }
  }, [input, comment, answer]);

  useEffect(() => {
    if (comment != input.comment || answer != input.answer) {
      updateQuestionAnswer({
        ...input,
        answerId: input.answerId,
        comment: comment,
        answer: answer,
      });
    }
  }, [comment, answer]);

  return (
    <div>
      <QuestionWithAnswer
        index={index}
        input={input}
        comment={comment}
        setComment={setComment}
        answer={answer}
        setAnswer={setAnswer}
        depth={0}
      />
      {children
        ?.filter((x) => x.requiredAnswerToBeVisible == answer.toLowerCase())
        ?.sort((a, b) => (new Date(a.created) > new Date(b.created) ? 1 : -1))
        .map((item, i) => (
          <Row
            index={index + "." + (i + 1)}
            key={index + item.weight + item.questionId}
            input={item}
            reportId={reportId}
            depth={1}
          ></Row>
        ))}
    </div>
  );
};
