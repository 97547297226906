import { toast } from "react-toastify";

export const successToast = () => {
  toast.success("Zapisano");
};

export const failureSaveToast = (name: string) => {
  toast.error("Nie udało się zapisać " + name);
};

export const failurToast = (name: string) => {
  toast.error(name);
};

export const lockToast = () => {
  toast.warn("Szablon zablokowany");
};

export const notEnoughPermissionToast = () => {
  toast.warn("Niewystarczające uprawnienia");
};
