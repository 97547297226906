import { useEffect, useState } from "react";
import { Metadata } from "../../types/Metadata";
import { ParticipantsInput } from "./metadata/participantsInput";
import { ProtocolMetadata } from "./metadata/protocolMetadata";
import { useMetadata } from "../..";
import { useIsFirstRender } from "@uidotdev/usehooks";
import { ItemPopover } from "../../components/atomic/itemPopover";

export const ReportHeader = ({
  name,
  address,
  resortName,
  templateId,
  metadata,
  isDisabled = false,
}: {
  name: string;
  address: string;
  resortName: string;
  templateId: string;
  metadata: Metadata | undefined;
  isDisabled?: boolean;
}) => {
  const isFirstRender = useIsFirstRender();
  const [declaredParticipants, setDeclaredParticipants] = useState<number>(
    metadata?.declaredParticipants
  );
  const [afterScreening, setAfterScreening] = useState<number>(
    metadata?.afterScreening
  );
  const [randomized, setRandomized] = useState<number>(metadata?.randomized);
  const [screenFailure, setScreenFailure] = useState<number>(
    metadata?.screenFailure
  );
  const [duringFollowUp, setDuringFollowUp] = useState<number>(
    metadata?.duringFollowUp
  );
  const [duringTreatment, setDuringTreatment] = useState<number>(
    metadata?.duringTreatment
  );
  const [prematureCompletion, setPrematureCompletion] = useState<number>(
    metadata?.prematureCompletion
  );
  const [completedStudy, setCompletedStudy] = useState<number>(
    metadata?.completedStudy
  );
  const [recrutationStatus, setRecrutationStatus] = useState<number>(
    metadata?.recrutationStatus
  );
  const [protocolDeviation, setProtocolDeviation] = useState<number>(
    metadata?.protocolDeviation
  );
  const [reportedSAE, setReportedSAE] = useState<number>(metadata?.reportedSAE);

  const { updateMetadata } = useMetadata();

  useEffect(() => {
    if (metadata)
      updateMetadata((md) => {
        return metadata;
      });
  }, [metadata]); // should be invoked only when fetching metadata

  useEffect(() => {
    if (!isFirstRender) {
      let val = 0;
      if (randomized != 0 && declaredParticipants != 0) {
        val = ((randomized ?? 1) / (declaredParticipants ?? 1)) * 100 ?? 0;
      }

      const calculatedValue = Math.round(val);
      if (calculatedValue != recrutationStatus && calculatedValue != 0) {
        setRecrutationStatus(calculatedValue);
      }
    }
  }, [randomized, declaredParticipants]);

  return (
    <>
      <div className="m-5">
        <div className="text-center text-2xl">
          Raport z{" "}
          <a
            href={"/templates/" + templateId}
            className="text-2xl font-extrabold dark:text-white text-blue-500"
          >
            {name}{" "}
          </a>{" "}
          w ośrodku{" "}
          <span className="text-2xl dark:text-white">{resortName}</span>
        </div>
        <div className="text-center text pb-5 text-gray-500">{address} </div>
        <div className="my-5">
          <ParticipantsInput metadata={metadata} />
        </div>
        <div className="my-3">
          <ProtocolMetadata metadata={metadata} />
        </div>
        <div className="grid grid-cols-3 mb-5">
          <div className="col-span-1 flex justify-end">
            <p>
              Status rekrutacji:{" "}
              <span>
                <input
                  className="border"
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? "lightgray" : "none",
                    width: "50px",
                  }}
                  value={recrutationStatus ?? 0}
                  type="number"
                  onChange={(e) => {
                    setRecrutationStatus(Number.parseInt(e.target.value));
                    updateMetadata((metadata: Metadata) => {
                      metadata.recrutationStatus = Number.parseInt(
                        e.target.value
                      );
                      return metadata;
                    });
                  }}
                ></input>
                %
              </span>
            </p>
          </div>
          <div className="col-span-1 flex justify-center">
            <p>
              Odchylenia od protokołu:{" "}
              <ItemPopover text="Wpisz liczbę odchyleń od protokołu zidentyfikowanych od poprzedniej wizyty." />
              <span>
                <input
                  className="border"
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? "lightgray" : "none",
                    width: "50px",
                  }}
                  value={protocolDeviation}
                  type="number"
                  onChange={(e) => {
                    setProtocolDeviation(Number.parseInt(e.target.value));
                    updateMetadata((metadata: Metadata) => {
                      metadata.protocolDeviation = Number.parseInt(
                        e.target.value
                      );
                      return metadata;
                    });
                  }}
                ></input>
              </span>
            </p>
          </div>

          <div className="col-span-1 flex justify-start">
            <p>
              SAE:{" "}
              <ItemPopover text="Wpisz liczbę SAE zidentyfikowanych od poprzedniej wizyty." />
              <span>
                <input
                  className="border"
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? "lightgray" : "none",
                    width: "50px",
                  }}
                  type="number"
                  value={reportedSAE}
                  onChange={(e) => {
                    setReportedSAE(Number.parseInt(e.target.value));
                    updateMetadata((metadata: Metadata) => {
                      metadata.reportedSAE = Number.parseInt(e.target.value);
                      return metadata;
                    });
                  }}
                ></input>
              </span>
            </p>
          </div>
        </div>

        <div className="sm:rounded-lg border">
          <div
            className="grid grid-cols-8
              sm:rounded-lg
              text-sm
                 bg-gray-50
                   divide-x text-sm"
          >
            <div className="col-span-1 p-3">
              <p className="">Zadeklarowani uczestnicy <ItemPopover text={"Wpisz liczbę uczestników zadeklarowanych przez Badacza i Ośrodek w umowie CSA."} /></p>
            </div>
            <div className="col-span-1 p-3">Skriningowani</div>
            <div className="col-span-1 p-3">Zrandomizowani</div>
            <div className="col-span-1 p-3">Z niepowodzeniem skriningu</div>
            <div className="col-span-1 p-3">W trakcie leczenia</div>
            <div className="col-span-1 p-3">W trakcie obserwacji</div>
            <div className="col-span-1 p-3">
              Kończący badania przedwcześnie EOT
            </div>
            <div className="col-span-1 p-3">Zakończony udział EOS</div>
          </div>

          <div
            className="grid grid-cols-8
                  max-h-50
                  divide-x border"
          >
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={declaredParticipants}
                onChange={(e) => {
                  setDeclaredParticipants(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.declaredParticipants = Number.parseInt(
                      e.target.value
                    );
                    return metadata;
                  });
                }}
              ></input>
            </div>
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={afterScreening}
                onChange={(e) => {
                  setAfterScreening(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.afterScreening = Number.parseInt(e.target.value);
                    return metadata;
                  });
                }}
              ></input>
            </div>
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={randomized}
                onChange={(e) => {
                  setRandomized(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.randomized = Number.parseInt(e.target.value);
                    return metadata;
                  });
                }}
              ></input>
            </div>
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={screenFailure}
                onChange={(e) => {
                  setScreenFailure(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.screenFailure = Number.parseInt(e.target.value);
                    return metadata;
                  });
                }}
              ></input>
            </div>
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={duringTreatment}
                onChange={(e) => {
                  setDuringTreatment(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.duringTreatment = Number.parseInt(e.target.value);
                    return metadata;
                  });
                }}
              ></input>
            </div>
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={duringFollowUp}
                onChange={(e) => {
                  setDuringFollowUp(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.duringFollowUp = Number.parseInt(e.target.value);
                    return metadata;
                  });
                }}
              ></input>
            </div>
            <div>
              <div className="col-span-1">
                <input
                  className="border"
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? "lightgray" : "none",
                    width: "99%",
                    height: "100%",
                  }}
                  type="number"
                  value={prematureCompletion}
                  onChange={(e) => {
                    setPrematureCompletion(Number.parseInt(e.target.value));
                    updateMetadata((metadata: Metadata) => {
                      metadata.prematureCompletion = Number.parseInt(
                        e.target.value
                      );
                      return metadata;
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="col-span-1">
              <input
                className="border"
                disabled={isDisabled}
                style={{
                  backgroundColor: isDisabled ? "lightgray" : "none",
                  width: "99%",
                  height: "100%",
                }}
                type="number"
                value={completedStudy}
                onChange={(e) => {
                  setCompletedStudy(Number.parseInt(e.target.value));
                  updateMetadata((metadata: Metadata) => {
                    metadata.completedStudy = Number.parseInt(e.target.value);
                    return metadata;
                  });
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
