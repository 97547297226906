import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import { Router } from "./Router";
import AuthProvider from "./pages/user/authProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const App = ({ instance }) => {
  const QueryWrapper = ({ children }: any) => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          networkMode: "always",
          refetchOnMount: true,
          refetchOnWindowFocus: false,
          // enabled: !!token,
        },
      },
    });
    return (
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    );
  };

  const activeAccount = instance.getActiveAccount();

  return (
    <>
      <MsalProvider instance={instance}>
        <AuthProvider>
          {/*  <AuthProvider> */}
          <ToastContainer
            position="bottom-left"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />

          <QueryWrapper>
            <Router />
          </QueryWrapper>

          {/* </AuthProvider> */}
        </AuthProvider>
      </MsalProvider>
    </>
  );
};
