import React from "react";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Chip,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { IoInformationCircle } from "react-icons/io5";

export const ItemPopover = ({ text }: { text: string }) => {
  const [openPopover, setOpenPopover] = React.useState(false);

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  return (
    <Popover open={openPopover} handler={setOpenPopover}>
      <PopoverHandler {...triggers}>
        <IconButton variant="text" className="rounded-full">
          {" "}
          <IoInformationCircle size={20} style={{ display: "inline" }} />
        </IconButton>
      </PopoverHandler>
      <PopoverContent {...triggers} style={{ width: "200px", maxWidth:'200px'}}>
        {text}
      </PopoverContent>
    </Popover>
  );
};
