import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { loginUser } from "../../api/userApi";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./ab2cAuthProvider";
import axios from "axios";


export const Login = () => {
  const { instance, inProgress } = useMsal();
  const [isToken, setIsToken] = useState(false);

  var logo = require("../../assets/logo-scientia.png");

  const handleLoginPopup = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri: "/",
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let t = async () => {
      const token = await instance.acquireTokenSilent(loginRequest);
      localStorage.setItem("webApiAccessToken", token.accessToken);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.accessToken}`;
      setIsToken(true);
    };
    if (inProgress != "startup" && instance?.getActiveAccount() && !isToken) {
      t();
    }
  }, [instance, inProgress]);

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img src={logo} alt="Logo" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div>
            <button
              onClick={() => handleLoginPopup()}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Zaloguj się
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
