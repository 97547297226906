import { RaportSection, ReviewComment } from "../../../types/Rows";
import { ReportSection } from "./QuestionList";

export const SectionsList = ({
  templateId,
  sections,
  reportId,
}: {
  templateId: string;
  sections: RaportSection[];
  reportId: string;
}) => {
  return (
    <div>
      <div className="text-xl text-center mt-1">Pytania</div>
      {sections
        .sort((a, b) => (a.weight < b.weight ? -1 : 1))
        .map((section, index) => {
          return (
            <div className="" key={section.id}>
              <ReportSection
                index={index + 1}
                section={section}
                items={section.questionsWithAnswer}
                templateId={templateId}
                reportId={reportId}
              />
            </div>
          );
        })}
    </div>
  );
};
