import axios from "axios";
import { ApiURI } from "../types/configs";
import { QuestionDTO, QuestionWithAnswers } from "../types/Rows";
import { headers } from "./headers";
import {
  ProblemDictionaryWithReportsList,
  ProblemsDictionary,
} from "../types/Metadata";

export const ReportsInstance = axios.create({
  baseURL: ApiURI + "Questions/",
});

export const getAllQuestions = async () => {
  let result = await ReportsInstance.get<QuestionDTO[]>("", headers());
  return result.data;
};

export const getQuestion = async (id: string) => {
  let result = await ReportsInstance.get<QuestionDTO>(id, headers());
  return result.data;
};

export const getTemplateProblems = async () => {
  return (
    await ReportsInstance.get<ProblemsDictionary>(
      "problemsDictionary",
      headers()
    )
  ).data;
};

export const getTemplateProblem = async (id: string) => {
  return (
    await ReportsInstance.get<ProblemDictionaryWithReportsList>(
      "/problemsDictionary/" + id,
      headers()
    )
  ).data;
};


export const deleteTemplateProblem = async (id: string) => {
  return (
    await ReportsInstance.delete<ProblemDictionaryWithReportsList>(
      "/problemsDictionary/" + id,
      headers()
    )
  ).data;
};