import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParticipantAnswers } from "../..";
import { addParticipant, removeParticipant } from "../../api/reportsApi";
import { TextAreaInputShort } from "../../components/atomic/commentsInput";
import { DiscussionComponent } from "../../components/atomic/discussionComponent";
import { PlusIcon } from "../../types/PlusIcon";
import { ParticipantAnswer } from "../../types/Rows";
import AddItemModal from "../template/addItemModal";
import { RemoveItemComponent } from "../../components/atomic/removeItemComponent";

const ParticipantAnswerRow = ({
  input,
  participantNumber,
  comment,
  setComment,
  isVerified,
  isFinshed,
  setIsFinished,
  setVisitNumber,
  setIsVerified,
  visitNumber,
  reportId,
}: {
  input: ParticipantAnswer;
  comment: string;
  participantNumber: string;
  setComment: (name: string) => void;
  isVerified: boolean;
  isFinshed: boolean;
  setIsFinished: (val: boolean) => void;
  setVisitNumber: (val: string) => void;
  setIsVerified: (val: boolean) => void;
  visitNumber: string;
  reportId: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate: remove } = useMutation(
    async (id: string) => removeParticipant(reportId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([reportId, "Participants"]);
      },
    }
  );

  return (
    <div
      className="grid grid-cols-12
                     max-h-50
                     break-words p-2"
    >
      <div className="col-span-3 flex items-center justify-between">
        <TextAreaInputShort
          rowSize={2}
          label="Numer pacjenta"
          value={participantNumber}
          onChange={(e) => {}}
        />
      </div>
      <div className="col-span-3 flex items-center justify-between">
        <TextAreaInputShort
          maxLength={512}
          rowSize={2}
          label="Numer wizyty"
          value={visitNumber}
          onChange={(e) => setVisitNumber(e)}
        />
      </div>
      <div className="col-span-2 flex items-center justify-aroundo gap-3 p-1">
        <label>
          <input
            defaultChecked={isFinshed}
            type="checkbox"
            onChange={(val) => {
              console.log("val changed to ", val.target);
              setIsFinished(!isFinshed);
            }}
            name={"checkboxfinished-" + input.id}
          />{" "}
          100%
        </label>
        <label title="RBV">
          <input
            defaultChecked={isVerified}
            type="checkbox"
            onChange={(val) => setIsVerified(!isVerified)}
            name={"checkboxverified-" + input.id}
          />{" "}
          RBV
        </label>
      </div>
      <div className="col-span-3 flex items-center">
        <TextAreaInputShort
          maxLength={1000}
          rowSize={2}
          label="Komentarz"
          value={comment}
          onChange={(e) => setComment(e)}
        />
      </div>

      <div className="col-span-1 self-center flex justify-evenly">
        <div className="self-center cursor-pointer">
          <DiscussionComponent resourceId={input.id} />
        </div>
        <RemoveItemComponent action={() => remove(input.id)} />
      </div>
    </div>
  );
};

export const Row = ({
  input,
  index,
  reportId,
}: {
  input: ParticipantAnswer;
  index: number;
  reportId: string;
}) => {
  const [comment, setComment] = useState<string>(input.comment);
  const [participantNumber, setParticipantNumber] = useState<string>(
    input.participantNumber
  );
  const [visitNumber, setVisitNumber] = useState<string>(input.visitNumber);
  const [isVerified, setIsVerified] = useState<boolean>(input.isVerified);
  const [isFinshed, setIsFinshed] = useState<boolean>(input.isFinshed);
  const [isInitialRender, setIsinitialRender] = useState(true);
  const { updateParticipantAnswer } = useParticipantAnswers();

  useEffect(() => {
    if (!isInitialRender) {
      updateParticipantAnswer({
        ...input,
        comment: comment,
        visitNumber: visitNumber,
        isFinshed: isFinshed,
        isVerified: isVerified,
        // participantNumber: participantNumber,
      });
    }
    setIsinitialRender(false);
  }, [comment, visitNumber, isVerified, isFinshed, participantNumber]);

  return (
    <ParticipantAnswerRow
      reportId={reportId}
      input={input}
      comment={comment}
      setComment={setComment}
      setIsFinished={setIsFinshed}
      isVerified={isVerified}
      isFinshed={isFinshed}
      setIsVerified={setIsVerified}
      setVisitNumber={setVisitNumber}
      visitNumber={visitNumber}
      participantNumber={participantNumber}
    />
  );
};

export const ParticipantsSectionsList = ({
  reportId,
  items,
}: {
  reportId: string;
  items: ParticipantAnswer[];
}) => {
  const queryClient = useQueryClient();

  const { mutate: addParticipantHandler } = useMutation(
    async (name: string) => addParticipant(reportId, name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([reportId, "Participants"]);
      },
    }
  );

  return (
    <div>
      <div className="text-xl text-center mt-1">Lista uczestników</div>
      <div className="sm:rounded-lg border border-neutral-900  m-3">
        <div
          className="grid grid-cols-12
                  w-full
                  sm:rounded-lg
                  font-extrabold
                  break-words
                text-gray-700 uppercase  bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <div className="col-span-3 flex items-center justify-between p-2">
            Numer uczestnika
          </div>
          <div className="col-span-3 flex items-center justify-between">
            Numer zweryfikowanej wizyty
          </div>
          <div className="col-span-2 flex items-center justify-between">
            SDV
          </div>

          <div className="col-span-3 flex items-center justify-between">
            Komentarz
          </div>
          <div className="col-span-1 flex items-center justify-end pr-2">
            <AddItemModal
              text="Wpisz numer uczestnika"
              saveItem={(name) => {
                addParticipantHandler(name);
              }}
            >
              <PlusIcon />
            </AddItemModal>
          </div>
        </div>
        {items
          ?.sort((a, b) => (new Date(a.created) > new Date(b.created) ? 1 : -1))
          .map((item, index) => (
            <Row
              reportId={reportId}
              input={item}
              index={index}
              key={item.id}
            ></Row>
          ))}
      </div>
    </div>
  );
};
