import { useEffect } from "react";
import { Answer, EditableReportQuestion } from "../types/Rows";


export const UseQuestionsTree = ({items} : {items: EditableReportQuestion[] | Answer[]}) =>{  
  useEffect(() => {
    let questionsCopy = new Map<string, EditableReportQuestion>();
    items.forEach(q=>questionsCopy.set(q.id, q));

    items
      .sort((x) => (x.parentId == null ? -1 : 1))
      .forEach((element) => {
        if (element.parentId && questionsCopy.has(element.parentId)) {
          const val = questionsCopy.get(element.parentId);
          val.children = val.children
            ? [...val.children.filter((x) => x.id != element.id), element]
            : [element];
        }
      });

    questionsCopy = null;

    console.log('result of children tree is ', items);
  }, [items]);
}